import axios from 'axios';
import config from 'config';

const countryApi = axios.create({
    baseURL: `${config.countriesAPI.baseURL}/`,
    headers: {
        'Content-Type': 'application/json',
        'X-CSCAPI-KEY': config.countriesAPI.apiKey
    }
});

export default countryApi;
