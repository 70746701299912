import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';

const NodataCard = (props) => {
    const { onClick } = props;
    return (
        <Box
            sx={{
                width: '100%',
                height: '138px',
                border: '2px dashed #E3D7F9',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '5px',
                marginTop: 1,
                marginBottom: 1
            }}
        >
            <Typography variant="h5" sx={{ color: '#707070', fontSize: '16px', p: 1 }}>
                No items added
            </Typography>
            <Button variant="outlined" onClick={onClick}>
                Add New
            </Button>
        </Box>
    );
};

export default NodataCard;
