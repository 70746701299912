// Enum for environments
export const ProfileEditableFields = {
    INDUSTRY_TITLE: 'industryTitle',
    SKILLS: 'skills',
    TYPES_OF_CLIENTS: 'typesOfClients',
    INDUSTRY_EXPERIENCE: 'industryExpereince',
    EXPERIENCE_LEVEL: 'experienceLevel',
    LANGUAGES: 'languages',
    ABOUT_ME: 'aboutMe',
    PROJECT_INTEREST: 'projectInterest',
    CERTIFICATION: 'certification',
    ADD_CERTIFICATION: 'addCertification',
    PROJECTS: 'projects',
    ADD_PROJECT: 'addProject'
};
