import React, { Fragment, useState } from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DVDeleteConfirmationPopup from 'views/pages/Common/DVDeleteConfirmationPopup';
import PropTypes from 'prop-types';

const DeleteButtonProfile = (props) => {
    const { title, message, cancelButtonText, confrimButtonText, actionConfirmed, sx: additionalStyles } = props;
    const [showConfirmation, setShowConfirmation] = useState(false);

    return (
        <Fragment>
            <IconButton
                size="small"
                aria-label="Edit"
                sx={{
                    backgroundColor: '#FA6D6D2B', // Set your desired background color,
                    borderRadius: '5px',
                    '&:hover': {
                        backgroundColor: '#FA6D6D40' // Set your desired hover color
                    },
                    ...additionalStyles
                }}
                onClick={() => setShowConfirmation(true)}
            >
                <DeleteIcon fontSize="small" sx={{ color: '#FA6D6D' }} />
            </IconButton>
            <DVDeleteConfirmationPopup
                show={showConfirmation}
                title={title}
                message={message}
                cancelButtonText={cancelButtonText}
                confrimButtonText={confrimButtonText}
                cancelAction={() => setShowConfirmation(false)}
                confirmAction={() => {
                    actionConfirmed();
                    setShowConfirmation(false);
                }}
            />
        </Fragment>
    );
};
DeleteButtonProfile.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    cancelButtonText: PropTypes.string,
    confrimButtonText: PropTypes.string,
    actionConfirmed: PropTypes.func.isRequired
};
DeleteButtonProfile.defaultProps = {
    title: 'Remove',
    message: 'Are you sure you want to remove this section?',
    cancelButtonText: 'No, Cancel',
    confrimButtonText: 'Yes, Please'
};

export default DeleteButtonProfile;
