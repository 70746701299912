import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    Typography,
    CardContent,
    Backdrop,
    Box,
    Stack,
    Grid,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//Utils
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import axios from 'axios';
import config from 'config';

// style constant
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 600,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
            width: '94%',
            maxHeight: '100%',
            overflow: 'scroll'
        }
    }
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    bgcolor: 'white',
    borderRadius: 3,
    boxShadow: 30,
    p: 2
};

const EditTypesOfClients = (props) => {
    const { open, profileData, questionData, closePopup } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const accessToken = window.localStorage.getItem('access_token');
    const userId = window.localStorage.getItem('user_id');

    const [options, setOptions] = useState([
        { label: 'CTO', isSelected: false, id: 1 },
        { label: 'CPO', isSelected: false, id: 2 },
        { label: 'Head of Engineering', isSelected: false, id: 3 },
        { label: 'Product Lead', isSelected: false, id: 4 },
        { label: 'CEO', isSelected: false, id: 5 },
        { label: 'Experience Officer', isSelected: false, id: 6 },
        { label: 'Tech Lead', isSelected: false, id: 7 },
        { label: 'Other', isSelected: false, id: 8 }
    ]);
    const [isApiCalling, setIsApiCalling] = useState(false);

    const optionUpdated = (index) => {
        if (index < options.length) {
            // Create a copy of the options array to avoid mutating the state directly
            const updatedOptions = [...options];

            // Toggle the isSelected property of the specified option
            updatedOptions[index] = {
                ...updatedOptions[index],
                isSelected: !updatedOptions[index].isSelected
            };

            // Update the state with the new options array
            setOptions(updatedOptions);
        }
    };

    const showSnackbar = (message, variant) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: variant,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            close: true
        });
    };

    //MARK: APIs
    const saveDataApi = async (event) => {
        setIsApiCalling(true);
        const poll_options = options.filter((option) => option.isSelected).map(({ id, label }) => ({ id, content: label, ref: id }));

        try {
            const data = {
                contact: {
                    answers: [
                        {
                            answer_id: '',
                            created_at: new Date().toISOString(),
                            is_public: false,
                            poll_options,
                            file_uploads: [],
                            question_id: questionData.questionID,
                            share_id: '',
                            share_url: '',
                            type: 'poll'
                        }
                    ],
                    email: profileData.email
                }
            };

            // Make a POST request to your backend endpoint handling avatar uploads
            const response = await axios.post(`${config.restAPI.baseURL}/user/videoask/update`, data, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            });
            showSnackbar('Profile updated successfully', 'success');
            setIsApiCalling(false);
            closePopup();
        } catch (error) {
            console.error('updation failed:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };

    return (
        <Fragment>
            <Modal
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
            >
                <Box sx={{ ...style }}>
                    <CardContent>
                        <Stack direction={'row'} alignContent={'center'} display={'flex'} justifyContent={'space-between'}>
                            <Typography id="modal-modal-title" variant="h2" component="h2" mb={2}>
                                Types of Client
                            </Typography>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ background: '#F8F8F8', padding: 3, borderRadius: 3, width: '100%' }}>
                                    <Grid container spacing={2}>
                                        {options.map((option, index) => (
                                            <Grid key={index} item xs={12} md={12}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        sx={{
                                                            width: '100%',
                                                            textAlign: 'left'
                                                        }}
                                                        checked={option.isSelected}
                                                        control={
                                                            <Checkbox
                                                                checked={option.isSelected}
                                                                sx={{
                                                                    color: '#59E239',
                                                                    '&.Mui-checked': {
                                                                        color: '#59E239'
                                                                    }
                                                                }}
                                                                onChange={(event) => {
                                                                    optionUpdated(index);
                                                                }}
                                                                icon={<CircleUnchecked />}
                                                                checkedIcon={<CheckCircleIcon />}
                                                            />
                                                        }
                                                        componentsProps={{ typography: { color: 'black' } }}
                                                        label={option.label}
                                                    />
                                                    {/* <FormControlLabel
                                                        sx={{
                                                            width: '100%',
                                                            textAlign: 'left'
                                                        }}
                                                        checked={option.isSelected}
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    color: '#59E239',
                                                                    '&.Mui-checked': {
                                                                        color: '#59E239'
                                                                    }
                                                                }}
                                                                onChange={(event) => {
                                                                    optionUpdated(index);
                                                                }}
                                                                icon={option.isSelected ? <RadioButtonCheckedIcon /> : <CircleUnchecked />}
                                                            />
                                                        }
                                                        componentsProps={{ typography: { color: 'black' } }}
                                                        label={option.label}
                                                    /> */}
                                                </FormGroup>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            variant="text"
                                            type="button"
                                            onClick={closePopup}
                                            style={{ backgroundColor: '#dbedfd' }}
                                            className={classes.button}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            disableElevation
                                            type="submit"
                                            size="large"
                                            className={classes.button}
                                            disabled={isApiCalling || options.filter((intst) => intst.isSelected).length == 0}
                                            onClick={saveDataApi}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Box>
            </Modal>
        </Fragment>
    );
};

EditTypesOfClients.propTypes = {
    open: PropTypes.bool,
    profileData: PropTypes.object,
    questionData: PropTypes.object,
    closePopup: PropTypes.func
};

export default EditTypesOfClients;
