import React, { Fragment } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@material-ui/styles';
const EditButtonProfile = (props) => {
    const { buttonTapped, sx: additionalStyles } = props;
    const theme = useTheme();
    return (
        <Fragment>
            <IconButton
                size="small"
                aria-label="Edit"
                sx={{
                    backgroundColor: '#E3F2FD', // Set your desired background color,
                    borderRadius: '5px',
                    '&:hover': {
                        backgroundColor: '#bbd8f9' // Set your desired hover color
                    },
                    ...additionalStyles
                }}
                onClick={buttonTapped}
            >
                <EditIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
            </IconButton>
        </Fragment>
    );
};

export default EditButtonProfile;
