import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import './player.css';
import { Slider } from '@material-ui/core';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';

const VideoPlayer = (props) => {
    const { videoURL } = props;
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.5);
    const [played, setPlayed] = useState(0);
    const [showControls, setShowControls] = useState(true);
    const playerRef = useRef(null);

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const handleVolumeChange = (e) => {
        setVolume(parseFloat(e.target.value));
    };

    const handleProgress = (state) => {
        setPlayed(state.played);
    };

    const handleSeekChange = (e, newValue) => {
        const fraction = parseFloat(newValue);
        if (!isNaN(fraction) && isFinite(fraction) && fraction >= 0 && fraction <= 1) {
            setPlayed(fraction);
            playerRef.current.seekTo(fraction, 'fraction');
        }
    };

    const handleFullscreen = () => {
        const player = playerRef.current.getInternalPlayer();
        if (player) {
            if (player.requestFullscreen) {
                player.requestFullscreen();
            } else if (player.mozRequestFullScreen) {
                player.mozRequestFullScreen();
            } else if (player.webkitRequestFullscreen) {
                player.webkitRequestFullscreen();
            } else if (player.msRequestFullscreen) {
                player.msRequestFullscreen();
            }
        }
    };

    const handleMouseEnter = () => {
        setShowControls(true);
    };

    const handleMouseLeave = () => {
        // if (!playing) {
        setShowControls(false);
        // }
    };
    const handleRewind = () => {
        const newTime = Math.max(playerRef.current.getCurrentTime() - 15, 0);
        setPlayed(newTime / playerRef.current.getDuration());
        playerRef.current.seekTo(newTime);
    };

    const handleFastForward = () => {
        const newTime = Math.min(playerRef.current.getCurrentTime() + 15, playerRef.current.getDuration());
        setPlayed(newTime / playerRef.current.getDuration());
        playerRef.current.seekTo(newTime);
    };
    return (
        <div className="custom-video-player" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} id="video">
            <ReactPlayer
                ref={playerRef}
                url={videoURL}
                width="100%"
                height="100%"
                playing={playing}
                volume={volume}
                onProgress={handleProgress}
            />
            {showControls && (
                <>
                    <button style={{}} onClick={handlePlayPause}>
                        {playing ? <PauseIcon sx={{ fontSize: '50px' }} /> : <PlayArrowIcon sx={{ fontSize: '50px' }} />}
                    </button>
                    <div className="history_icon" onClick={() => handleRewind()}>
                        <FastRewindIcon sx={{ color: '#5e35b1', fontSize: '40px' }} />
                    </div>
                    <div className="forward_icon" onClick={() => handleFastForward()}>
                        <FastForwardIcon sx={{ color: '#5e35b1', fontSize: '40px' }} />
                    </div>
                    <div className="controls">
                        <div className="seak_class">
                            <Slider
                                min={0}
                                max={1}
                                step={0.01}
                                value={played}
                                onChange={handleSeekChange}
                                aria-labelledby="continuous-slider"
                                sx={{ width: '100%', color: '#5e35b1' }}
                            />
                        </div>
                        <div className="seak_vol">
                            <VolumeUpIcon sx={{ color: '#5e35b1' }} />
                            <Slider
                                min={0}
                                max={1}
                                step={0.01}
                                value={volume}
                                onChange={handleVolumeChange}
                                aria-labelledby="continuous-slider"
                                sx={{ width: '100%', color: '#5e35b1' }}
                            />
                        </div>
                        <div onClick={handleFullscreen} style={{ cursor: 'pointer' }}>
                            <FullscreenIcon sx={{ color: '#5e35b1' }} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default VideoPlayer;
