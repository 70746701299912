import { Box, Modal, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VideoPlayer from 'utils/player/VideoPlayer';

const VideoCard = (props) => {
    const { thumbnail, videoURL } = props;
    const [modal, setModal] = useState(false);
    return (
        <>
            <Box
                onClick={() => setModal(true)}
                sx={{
                    height: 120,
                    backgroundColor: '#F5F5F5',
                    borderRadius: 3,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0px 20px',
                    gap: '10px',
                    cursor: 'pointer',
                    marginTop: 1
                }}
            >
                <Box
                    sx={{
                        width: 80,
                        height: 80,
                        backgroundImage: `url(${thumbnail})`,
                        borderRadius: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundSize: 'cover',
                        backgroundColor: '#000',
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    <Box sx={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: '#00000050', top: 0, left: 0 }}></Box>
                    <PlayArrowIcon sx={{ fontSize: 50, color: '#fff', position: 'absolute' }} />
                </Box>
                <Typography variant="h4" sx={{ color: '#7439E2', fontWeight: 500, fontSize: '14px' }}>
                    Play Video
                </Typography>
            </Box>
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    sx={{
                        width: '90%',
                        maxHeight: '90%',
                        position: 'relative',
                        backgroundColor: '#fff',
                        borderRadius: 5,
                        overflow: 'hidden'
                    }}
                >
                    <VideoPlayer videoURL={videoURL} />
                </Box>
            </Modal>
        </>
    );
};

export default VideoCard;
