// Enum for environments
const Environment = {
    Development: 'Development',
    Staging: 'Staging',
    Production: 'Production'
};

// Configuration object
const environmentConfig = {
    [Environment.Development]: {
        baseUrl: 'https://dev.digitalvillage.app/api/v1',
        invoiceAPIUrl: 'https://xerodev.digitalvillage.app'
    },
    [Environment.Staging]: {
        baseUrl: 'https://staging-v2.digitalvillage.app/api/v1',
        invoiceAPIUrl: 'https://xero-staging.digitalvillage.app'
    },
    [Environment.Production]: {
        baseUrl: 'https://appv2beta.digitalvillage.app/api/v1',
        invoiceAPIUrl: 'https://xero.digitalvillage.app'
    }
};

//Signup external URL
export const _SIGNUP_URL_ = 'https://welcome.digitalvillage.app/fxsspf017';

// MARK: CONFIGURE URL HERE..........................
// =============================================== //
// *********************************************** //
export const targetEnvironment = Environment.Development; // Change this to switch environments
// *********************************************** //
// =============================================== //
// MARK: CONFIGURE URL ENDS HERE.....................
export const appVersionText = 'v1.0.1.1.38, (15 Jul 2024)';

// Get the base URL for the target environment
export function getBaseUrl(environment) {
    console.log('🚀 APP VERSION 🚀 : ', appVersionText);
    console.log('🚀 ~ file: config.js:35 ~ environmentConfig:', environmentConfig[environment].baseUrl);
    return environmentConfig[environment].baseUrl;
}

// Get the invoice API URL for the target environment
export function getInvoiceBaseUrl(environment) {
    console.log(
        '🚀 ~ file: config.js:42 ~ getInvoiceBaseUrl ~ environmentConfig[environment].invoiceAPIUrl:',
        environmentConfig[environment].invoiceAPIUrl
    );
    return environmentConfig[environment].invoiceAPIUrl;
}

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/stage',
    defaultPath: '/profile/dashboard',
    defaultPathAdmin: '/profile/profile-projects/my-projects',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    i18n: 'en',
    rtlLayout: false,
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    },
    firebase: {
        apiKey: 'AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM',
        authDomain: 'berry-material-react.firebaseapp.com',
        projectId: 'berry-material-react',
        storageBucket: 'berry-material-react.appspot.com',
        messagingSenderId: '901111229354',
        appId: '1:901111229354:web:a5ae5aa95486297d69d9d3',
        measurementId: 'G-MGJHSL8XW3'
    },
    auth0: {
        client_id: 'HvYn25WaEHb7v5PBT7cTYe98XATStX3r',
        domain: 'demo-localhost.us.auth0.com'
    },
    restAPI: {
        baseURL: getBaseUrl(targetEnvironment)
        // process.env.NODE_ENV === 'development'
        //     ? 'https://appv2beta.digitalvillage.app/api/v1'
        //     : 'https://appv2beta.digitalvillage.app/api/v1'
        // process.env.NODE_ENV === 'development' ? 'https://dev.digitalvillage.app/api/v1' : 'https://appv2beta.digitalvillage.app/api/v1'
        // process.env.NODE_ENV === 'development' ? 'https://dev.digitalvillage.app/api/v1' : 'https://dev.digitalvillage.app/api/v1'
        // targetEnvironment ? 'https://dev.digitalvillage.app/api/v1' : 'https://appv2beta.digitalvillage.app/api/v1'
    },
    invoiceAPI: {
        // baseURL: process.env.NODE_ENV === 'development' ? 'https://xero.digitalvillage.app' : 'https://xero.digitalvillage.app'
        // baseURL: process.env.NODE_ENV === 'development' ? 'https://xerodev.digitalvillage.app' : 'https://xerodev.digitalvillage.app'
        baseURL: getInvoiceBaseUrl(targetEnvironment)
        // targetEnvironment ? 'https://xerodev.digitalvillage.app' : 'https://xero.digitalvillage.app'
    },
    countriesAPI: {
        baseURL: 'https://api.countrystatecity.in/v1',
        apiKey: 'cndQQ3VORUR4cm42ZEVuZHl4RUY3b3R5eDczSWtwOVR5WXkxWHVQZQ=='
    }
};

export default config;
