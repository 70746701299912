import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography, Grid } from '@material-ui/core';

const DVDeleteConfirmationPopup = (props) => {
    const { show, title, message, cancelButtonText, confrimButtonText, cancelAction, confirmAction } = props;

    return (
        <Dialog
            open={show}
            onClose={cancelAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ p: 3 }}
        >
            <DialogTitle sx={{ fontWeight: 'bold', fontSize: '22px' }} id="alert-dialog-title" style={{ textAlign: 'center' }}>
                {title}
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2} sx={{ pr: 2, pl: 2 }}>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            sx={{
                                background: '#E5F4FF',
                                color: '#0094FF',
                                height: '45px',
                                borderRadius: 2,
                                fontSize: '16px',
                                fontWeight: 'medium',
                                '&:hover': {
                                    backgroundColor: '#E5F4FF',
                                    color: '#0094FF'
                                },
                                width: '100%'
                            }}
                            disableElevation
                            onClick={cancelAction}
                        >
                            {cancelButtonText}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            sx={{
                                color: 'primary',
                                height: '45px',
                                borderRadius: 2,
                                fontSize: '16px',
                                fontWeight: 'medium',
                                width: '100%',
                                background: '#FA6D6D',
                                '&:hover': {
                                    background: '#FA6D6D'
                                }
                            }}
                            disableElevation
                            size="small"
                            onClick={confirmAction}
                            autoFocus
                        >
                            {confrimButtonText}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default DVDeleteConfirmationPopup;
