import React, { Fragment, useState, useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import config from 'config';
import axios from 'axios';
import Country from '../../../../assets/json/Country.json';

// material-ui
import { makeStyles } from '@material-ui/styles';
import {
    Box,
    Modal,
    Grid,
    Button,
    TextField,
    CardContent,
    Autocomplete,
    Checkbox,
    Slider,
    FormControlLabel,
    FormGroup,
    CircularProgress
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//component import
import MainCard from 'ui-component/cards/MainCard';

//app imports
import { ProfileEditableFields } from './ProfileEditableFields';
import {
    addCertificateApi,
    addIndustryExperiencesApi,
    addSkillApi,
    updateAboutApi,
    updateTitleApi,
    updateExpLevelApi,
    updateLanguagesListApi,
    updateCertificateApi,
    addProjectApi,
    updateProjectApi,
    updateProjectInterestsApi
} from 'api/accounts';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';

// style constant
const useStyles = makeStyles((theme) => ({
    accountTab: {
        marginBottom: '24px',
        minHeight: 'auto',
        '& button': {
            minWidth: '100px'
        },
        '& a': {
            minHeight: 'auto',
            minWidth: '10px',
            padding: '12px 8px',
            marginRight: '18px',
            color: theme.palette.grey[600]
        },
        '& a.Mui-selected': {
            color: theme.palette.primary.main
        }
    },
    paper: {
        position: 'absolute',
        width: 430,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
            width: '94%',
            maxHeight: '100%',
            overflow: 'scroll'
        }
    },
    button: {
        borderRadius: 8,
        fontSize: '16px',
        fontWeight: 'medium'
    }
}));

const EditProfilePopups = (props) => {
    const classes = useStyles();
    const { showPopUp, editableField, dismissPopUp, profileData, selectedCertificate, selectedProject } = props;
    const dispatch = useDispatch();

    const [isApiCalling, setIsApiCalling] = useState(false);
    // MARK: Profile data
    const [tempProfileData, setTempProfileData] = useState(profileData);

    // MARK: Skills array
    const modifySkillsData = (data) => {
        return data?.map(({ skill_id, specialist_id, updatedAt, createdAt, ...rest }) => ({
            ...rest,
            id: rest.dv_skills.id // Rename 'dv_skills.id' to 'id'
            // Add other modifications if needed
        }));
    };

    const [skillsArray, setSkillsArray] = useState([]);
    const [skillsChipData, setSkillsChipData] = useState(() => modifySkillsData(profileData?.specialist_skills)); //useState(skills);
    //MARK: Industry experience
    const [indusExpArray, setIndusExpArray] = useState([]);
    const [invalidProjectData, setInvalidProjectData] = useState(false);

    //MARK: INDUSTRY EXPERIENCE
    const [indusExpChipData, setIndusExpChipData] = useState(profileData?.industry_experience); //useState(skills);

    //MARK: CERTIFICATES
    const [newCertificate, setNewCertificate] = useState({
        name: '',
        degree: '',
        description: '',
        specialisation: '',
        started_at: '',
        finished_at: ''
    });
    const [invalidCertificateData, setInvalidCertificateData] = useState(false);

    //MARK: PROJECTS
    const [newProject, setNewProject] = useState({
        name: '',
        position: '',
        started_at: '',
        finished_at: '',
        description: '',
        city: '',
        country: ''
    });

    //MARK: Experience level
    const [expLevel, setExpLevel] = useState(null); //useState(expLevel);
    // console.log(props.selectedProject, 'pro____________________data');

    const expLevelOptions = [
        {
            value: 1,
            label: 'Beginner'
        },
        {
            value: 2,
            label: 'Mid-weight'
        },
        {
            value: 3,
            label: 'Experienced'
        }
    ];
    //MARK: Languages
    const [langaugesArray, setLangaugesArray] = useState([]);
    const [languagesChipData, setLanguagesChipData] = useState(profileData.specialist_languages); //useState(languages);
    const accessToken = window.localStorage.getItem('access_token');

    //MARK: Project interests
    const [projInterestsArray, setProjInterestsArray] = useState([]);

    //MARK: YEAR/S SELECTION
    const [years, setYears] = useState(['2023']);

    //FUNCTIONS
    const showSnackbar = (message, variant) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: variant,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            close: true
        });
    };

    const projectInterestSelectionUpdated = (index) => {
        setProjInterestsArray((prevArray) => {
            return prevArray.map((interestData, i) => {
                if (i === index) {
                    return { ...interestData, selected: !interestData.selected };
                }
                return interestData;
            });
        });
    };

    //update skills
    const handleSaveSkills = async () => {
        try {
            //Filter-out if duplicates found
            const filteredSkillsSet = new Set();
            const filteredSkills = skillsChipData
                .map((item) => {
                    const id = item.dv_skills.id;
                    if (!filteredSkillsSet.has(id)) {
                        filteredSkillsSet.add(id);
                        return id;
                    }
                    return null;
                })
                .filter((id) => id !== null);

            // Wait for the addSkill API call to complete
            setIsApiCalling(true);
            const skillUpdate = await addSkillApi(filteredSkills, props.profileData.id);
            showSnackbar('Skills updated', 'success');
            setIsApiCalling(false);
            // Dismiss the popup and update the state
            dismissPopUp();
            props.setUpdated((prev) => !prev);
        } catch (error) {
            console.error('Error updating skills:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };
    //update indusyty exp
    const handleIndusExpSave = async () => {
        try {
            //Filter-out if duplicates found
            const filteredExpSet = new Set();
            const filteredIndusExp = indusExpChipData
                .map((item) => {
                    const id = item.dv_industry_experiences?.id;
                    if (!filteredExpSet.has(id)) {
                        filteredExpSet.add(id);
                        return id;
                    }
                    return null;
                })
                .filter((id) => id !== null);

            // Wait for the API call to complete
            setIsApiCalling(true);
            const industryExpsUpdate = await addIndustryExperiencesApi(filteredIndusExp, props.profileData.id);
            showSnackbar('Industry experience updated', 'success');
            setIsApiCalling(false);
            // Dismiss the popup and update the state
            dismissPopUp();
            props.setUpdated((prev) => !prev);
        } catch (error) {
            console.error('Error updating:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };
    //update industry title
    const handleSaveIndustryTitle = async () => {
        try {
            // Wait for the API call to complete
            setIsApiCalling(true);
            const industryTitleUpdate = await updateTitleApi(tempProfileData.industry_title, tempProfileData.id);
            showSnackbar('Industry title updated', 'success');
            setIsApiCalling(false);
            dismissPopUp();
            props.setUpdated((prev) => !prev);
        } catch (error) {
            console.error('Error updating:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };
    //update exp level
    const handleExpLevelSave = async () => {
        try {
            // Wait for the API call to complete
            setIsApiCalling(true);
            const industryTitleUpdate = await updateExpLevelApi(expLevel, tempProfileData.id);
            showSnackbar('Experience level updated', 'success');
            setIsApiCalling(false);
            dismissPopUp();
            props.setUpdated((prev) => !prev);
        } catch (error) {
            console.error('Error updating:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };
    //update languages list
    const handleLangSave = async () => {
        try {
            //Filter-out if duplicates found
            const filteredLangSet = new Set();
            const filteredLang = languagesChipData
                .map((item) => {
                    const id = item.dv_language?.id;
                    if (!filteredLangSet.has(id)) {
                        filteredLangSet.add(id);
                        return id;
                    }
                    return null;
                })
                .filter((id) => id !== null);
            // Wait for the API call to complete
            setIsApiCalling(true);
            const langsUpdate = await updateLanguagesListApi(filteredLang, props.profileData.id);
            showSnackbar('Languages updated', 'success');
            setIsApiCalling(false);
            // Dismiss the popup and update the state
            dismissPopUp();
            props.setUpdated((prev) => !prev);
        } catch (error) {
            console.error('Error updating:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };
    //update about text
    const handleAboutSave = async () => {
        try {
            // Wait for the API call to complete
            setIsApiCalling(true);
            const aboutMe = await updateAboutApi(tempProfileData.professional_experience_info, tempProfileData.id);
            showSnackbar('Description updated', 'success');
            setIsApiCalling(false);
            dismissPopUp();
            props.setUpdated((prev) => !prev);
        } catch (error) {
            console.error('Error updating:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };
    //update certificates
    const handleUpdateCertificate = async () => {
        try {
            // Wait for the API call to complete
            setIsApiCalling(true);
            const certificateApi = await updateCertificateApi(newCertificate, tempProfileData.id);
            showSnackbar('Certificate updated', 'success');
            setIsApiCalling(false);
            dismissPopUp();
            props.setUpdated((prev) => !prev);
        } catch (error) {
            console.error('Error updating:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };
    //add certificate
    const handleAddCertificate = async () => {
        try {
            // Wait for the API call to complete
            setIsApiCalling(true);
            const certificateApi = await addCertificateApi(newCertificate, tempProfileData.id);
            showSnackbar('New certificate added', 'success');
            setIsApiCalling(false);
            dismissPopUp();
            props.setUpdated((prev) => !prev);
        } catch (error) {
            console.error('Error updating:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };

    //add project
    const handleProjectSave = async () => {
        try {
            // Wait for the API call to complete
            setIsApiCalling(true);
            const projectApi = await addProjectApi(newProject, tempProfileData.id);
            showSnackbar('New project added', 'success');
            setIsApiCalling(false);
            dismissPopUp();
            props.setUpdated((prev) => !prev);
        } catch (error) {
            console.error('Error updating:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };

    //update project
    const handleProjectUpdate = async () => {
        try {
            // Wait for the API call to complete
            setIsApiCalling(true);
            const projectApi = await updateProjectApi(newProject, tempProfileData.id);
            showSnackbar('Project updated', 'success');
            setIsApiCalling(false);
            dismissPopUp();
            props.setUpdated((prev) => !prev);
        } catch (error) {
            console.error('Error updating:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };

    //update project interest
    const handleProjectInterestUpdate = async () => {
        try {
            const projInterestsIds = projInterestsArray.filter((projInt) => projInt.selected).map((selectedItem) => selectedItem.id);
            // Wait for the API call to complete
            setIsApiCalling(true);
            const projectIntsApi = await updateProjectInterestsApi(projInterestsIds, tempProfileData.id);
            showSnackbar('Project interests updated', 'success');
            setIsApiCalling(false);
            dismissPopUp();
            props.setUpdated((prev) => !prev);
        } catch (error) {
            console.error('Error updating:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };

    //Common api
    const fetchData = async (url, setDataFunction) => {
        try {
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const result = response.data.data;
            setDataFunction(result);
        } catch (error) {
            // Handle error
            console.error('Error fetching data:', error);
        }
    };

    //USE EFFECT
    useEffect(() => {
        switch (editableField) {
            case ProfileEditableFields.SKILLS:
                fetchData(`${config.restAPI.baseURL}/user/skill/list`, setSkillsArray);
                break;
            case ProfileEditableFields.INDUSTRY_EXPERIENCE:
                fetch(`${config.restAPI.baseURL}/user/industry-experiance/list`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json'
                    }
                })
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            setIndusExpArray(result.data);
                            // console.log('🚀 ~ file: EditProfilePopups.js:453 ~ useEffect ~ Indus:', result.data);
                        },
                        (error) => {}
                    );
                break;
            case ProfileEditableFields.LANGUAGES:
                fetchData(`${config.restAPI.baseURL}/user/language/list`, setLangaugesArray);
                break;
            case ProfileEditableFields.PROJECT_INTEREST:
                fetch(`${config.restAPI.baseURL}/user/project-types/list`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            const projectIntrstArray = result.data.map((interestData) => ({
                                ...interestData,
                                selected: props.profileData.project_types.some(
                                    (item) => item.dv_project_types.id === interestData.dv_project_types.id
                                )
                            }));
                            setProjInterestsArray(projectIntrstArray);
                        },
                        (error) => {}
                    );
                break;
            case ProfileEditableFields.CERTIFICATION:
                setNewCertificate({
                    id: selectedCertificate.id,
                    name: selectedCertificate.name,
                    degree: selectedCertificate.degree,
                    description: selectedCertificate.description,
                    specialisation: selectedCertificate.specialisation,
                    started_at: selectedCertificate.started_at,
                    finished_at: selectedCertificate.finished_at
                });
            case ProfileEditableFields.ADD_CERTIFICATION:
            case ProfileEditableFields.ADD_PROJECT:
                const currentYearCert = new Date().getFullYear();
                const last30YearsCert = Array.from({ length: 30 }, (_, index) => (currentYearCert - index).toString());
                setYears(last30YearsCert);
                break;
            case ProfileEditableFields.PROJECTS:
                const currentYearPrj = new Date().getFullYear();
                const last30YearsPrj = Array.from({ length: 30 }, (_, index) => (currentYearPrj - index).toString());
                setYears(last30YearsPrj);
                setNewProject({
                    id: selectedProject.id,
                    name: selectedProject.name,
                    position: selectedProject.position,
                    started_at: selectedProject.started_at,
                    finished_at: selectedProject.finished_at,
                    description: selectedProject.description,
                    city: selectedProject.city,
                    country: selectedProject.country
                });
                break;
            default:
                break;
        }
    }, []);

    //Certificate
    useEffect(() => {
        // Exclude started_at and finished_at from the validation
        const { started_at, finished_at, description, id, ...restCertificate } = newCertificate;

        // Check if any value (excluding started_at and finished_at) in newCertificate is empty
        const hasEmptyValue = Object.values(restCertificate).some((value) => value?.trim() === '');

        let isValidDateRange = true;

        // Check if both started_at and finished_at are provided and validate the date range
        if (started_at !== '' && finished_at !== '') {
            const startedAtDate = new Date(started_at);
            const finishedAtDate = new Date(finished_at);
            isValidDateRange = finishedAtDate.getFullYear() >= startedAtDate.getFullYear();
        } else if (started_at === '' && finished_at !== '') {
            // If only finished_at is provided but started_at is not, the date range is invalid
            isValidDateRange = false;
        }

        // Show snackbar if the date range is invalid
        if (!isValidDateRange) {
            showSnackbar('Please select a valid date range', 'warning');
        }

        // Update the invalidCertificateData state
        setInvalidCertificateData(hasEmptyValue || !isValidDateRange);
    }, [newCertificate]);

    //Project
    useEffect(() => {
        // Exclude started_at and finished_at from the validation
        const { started_at, finished_at, description, id, ...restProject } = newProject;

        // Check if any value (excluding started_at and finished_at) in newCertificate is empty
        const hasEmptyValue = Object.values(restProject).some((value) => value?.trim() === '');

        let isValidDateRange = true;

        // Check if both started_at and finished_at are provided and validate the date range
        if (started_at !== '' && finished_at !== '') {
            console.log('🚀 ~ useEffect ~ finished_at:', finished_at);
            console.log('🚀 ~ useEffect ~ started_at:', started_at);
            const startedAtDate = new Date(started_at);
            const finishedAtDate = new Date(finished_at);
            isValidDateRange = finishedAtDate.getFullYear() >= startedAtDate.getFullYear();

            if (!isValidDateRange) {
                showSnackbar('Please select a valid date range', 'warning');
            }
        } else if (started_at === '' && finished_at !== '') {
            // If only finished_at is provided but started_at is not, the date range is invalid
            isValidDateRange = false;
            showSnackbar('Please select a start date', 'warning');
        }

        // Update the invalidCertificateData state
        setInvalidProjectData(hasEmptyValue || !isValidDateRange);
    }, [newProject]);

    return (
        <Fragment>
            {editableField === ProfileEditableFields.INDUSTRY_TITLE && (
                <Modal open={showPopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    <MainCard className={classes.paper} content={false}>
                        <CardContent>
                            <div item xs={12}>
                                <h2 fullWidth style={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
                                    Industry Title
                                </h2>
                            </div>

                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="industry_tile"
                                        name="industry_tile"
                                        label="Industry Title"
                                        value={tempProfileData?.industry_title}
                                        onChange={(e) => {
                                            setTempProfileData({ ...tempProfileData, industry_title: e.target.value });
                                        }}
                                        error={tempProfileData?.industry_title.trim() === ''}
                                        sx={{
                                            input: {
                                                background: 'white'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ padding: '0px 0px 10px 0px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="text"
                                                type="button"
                                                onClick={dismissPopUp}
                                                style={{ backgroundColor: '#dbedfd' }}
                                                className={classes.button}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                                type="submit"
                                                size="large"
                                                className={classes.button}
                                                onClick={() => handleSaveIndustryTitle()}
                                                disabled={tempProfileData?.industry_title.trim() === '' || isApiCalling}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </MainCard>
                </Modal>
            )}
            {editableField === ProfileEditableFields.SKILLS && (
                <Modal open={showPopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {/* Edit Skills - STARTS */}
                    <MainCard className={classes.paper} content={false}>
                        <CardContent>
                            <div item xs={12}>
                                <h2 fullWidth style={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
                                    Skills
                                </h2>
                            </div>

                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={skillsArray}
                                        value={skillsChipData}
                                        freeSolo
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option?.dv_skills?.skill_title}
                                        onChange={(event, newValue) => {
                                            // Ensure uniqueness of selected skills based on their IDs
                                            const uniqueSkills = Array.from(new Set(newValue.map((skill) => skill.id)));
                                            const uniqueSkillsData = skillsArray.filter((skill) => uniqueSkills.includes(skill.id));

                                            setSkillsChipData(uniqueSkillsData);
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    style={{ marginRight: 8 }}
                                                    checked={skillsChipData.some((e) => e.id === option.id)}
                                                />
                                                {option.dv_skills.skill_title}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Top Skills"
                                                placeholder="Top Skills"
                                                sx={{ mt: 2 }}
                                                size="medium"
                                                className="name_input"
                                                error={skillsChipData.length === 0}
                                            />
                                        )}
                                        sx={{
                                            '.MuiInputBase-root': {
                                                backgroundColor: 'white'
                                            },
                                            '.MuiInputBase-input': {
                                                height: '20px !important'
                                            },
                                            input: {
                                                background: 'white'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ padding: '0px 0px 10px 0px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="text"
                                                type="button"
                                                onClick={dismissPopUp}
                                                style={{ backgroundColor: '#dbedfd' }}
                                                className={classes.button}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                                type="submit"
                                                size="large"
                                                className={classes.button}
                                                onClick={() => {
                                                    handleSaveSkills();
                                                }}
                                                disabled={skillsChipData.length === 0 || isApiCalling}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </MainCard>
                    {/* Edit Skills - ENDS */}
                </Modal>
            )}
            {editableField === ProfileEditableFields.INDUSTRY_EXPERIENCE && (
                <Modal open={showPopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {/* Edit Industry experience - STARTS */}
                    <MainCard className={classes.paper} content={false}>
                        <CardContent>
                            <div item xs={12}>
                                <h2 fullWidth style={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
                                    Industry Experience
                                </h2>
                            </div>

                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={indusExpArray}
                                        value={indusExpChipData}
                                        freeSolo
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option?.dv_industry_experiences?.industry_experience}
                                        onChange={(event, newValue) => {
                                            // setIndusExpChipData(newValue);

                                            // Ensure uniqueness of selected exp based on their IDs
                                            const uniqueIndusExp = Array.from(
                                                new Set(newValue.map((indusExp) => indusExp?.dv_industry_experiences?.id))
                                            );
                                            const uniqueIndusExpData = indusExpArray.filter((indusExp) =>
                                                uniqueIndusExp.includes(indusExp?.dv_industry_experiences?.id)
                                            );

                                            setIndusExpChipData(uniqueIndusExpData);
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    style={{ marginRight: 8 }}
                                                    checked={
                                                        indusExpChipData.filter(
                                                            (e) => e.dv_industry_experiences.id === option.dv_industry_experiences.id
                                                        ).length > 0
                                                    }
                                                />
                                                {option?.dv_industry_experiences?.industry_experience}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Industry Experience"
                                                placeholder="Industry Experience"
                                                sx={{ mt: 2 }}
                                                size="medium"
                                                className="name_input"
                                                error={indusExpChipData.length === 0}
                                            />
                                        )}
                                        sx={{
                                            '.MuiInputBase-root': {
                                                backgroundColor: 'white'
                                            },
                                            '.MuiInputBase-input': {
                                                height: '20px !important'
                                            },
                                            input: {
                                                background: 'white'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ padding: '0px 0px 10px 0px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="text"
                                                type="button"
                                                onClick={dismissPopUp}
                                                style={{ backgroundColor: '#dbedfd' }}
                                                className={classes.button}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                                type="submit"
                                                size="large"
                                                className={classes.button}
                                                onClick={() => handleIndusExpSave()}
                                                disabled={indusExpChipData.length === 0 || isApiCalling}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </MainCard>
                    {/* Edit Industry experience - ENDS */}
                </Modal>
            )}

            {editableField === ProfileEditableFields.EXPERIENCE_LEVEL && (
                <Modal open={showPopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {/* Edit Experience level - STARTS */}
                    <MainCard className={classes.paper} content={false}>
                        <CardContent>
                            <div item xs={12}>
                                <h2 fullWidth style={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
                                    Experience level
                                </h2>
                            </div>

                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} textAlign="center">
                                    <Box
                                        sx={{
                                            border: 0,
                                            width: { md: '100%' },
                                            height: '100px'
                                        }}
                                    >
                                        <Slider
                                            aria-label="Always visible"
                                            // defaultValue={expLevel}
                                            defaultValue={props.profileData.experience_level_id}
                                            step={1}
                                            min={1}
                                            max={expLevelOptions.length}
                                            marks={expLevelOptions}
                                            onChange={(event) => {
                                                setExpLevel(event.target.value);
                                            }}
                                            size="medium"
                                            sx={{ mt: 3, width: { xs: '70%', md: '80%' } }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ padding: '0px 0px 10px 0px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="text"
                                                type="button"
                                                onClick={dismissPopUp}
                                                style={{ backgroundColor: '#dbedfd' }}
                                                className={classes.button}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                                type="submit"
                                                size="large"
                                                className={classes.button}
                                                onClick={() => handleExpLevelSave()}
                                                disabled={isApiCalling}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </MainCard>
                    {/* Edit Experience level - ENDS */}
                </Modal>
            )}

            {editableField === ProfileEditableFields.LANGUAGES && (
                <Modal open={showPopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {/* Edit Languages - STARTS */}
                    <MainCard className={classes.paper} content={false}>
                        <CardContent>
                            <div item xs={12}>
                                <h2 fullWidth style={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
                                    Languages
                                </h2>
                            </div>

                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={langaugesArray}
                                        value={languagesChipData}
                                        freeSolo
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.dv_language.language_title}
                                        onChange={(event, newValue) => {
                                            // Ensure uniqueness of selected langauge based on their IDs
                                            const uniqueLanguages = Array.from(new Set(newValue.map((lang) => lang?.dv_language?.id)));
                                            const uniqueLanguagesData = langaugesArray.filter((lang) =>
                                                uniqueLanguages.includes(lang?.dv_language?.id)
                                            );
                                            setLanguagesChipData(uniqueLanguagesData);
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    style={{ marginRight: 8 }}
                                                    checked={
                                                        languagesChipData.filter((e) => e.dv_language.id === option.dv_language.id).length >
                                                        0
                                                    }
                                                />
                                                {option.dv_language.language_title}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Languages"
                                                placeholder="Languages"
                                                sx={{ mt: 2 }}
                                                size="medium"
                                                className="name_input"
                                                error={languagesChipData.length === 0}
                                            />
                                        )}
                                        sx={{
                                            '.MuiInputBase-root': {
                                                backgroundColor: 'white'
                                            },
                                            '.MuiInputBase-input': {
                                                height: '20px !important'
                                            },
                                            input: {
                                                background: 'white'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ padding: '0px 0px 10px 0px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="text"
                                                type="button"
                                                onClick={dismissPopUp}
                                                style={{ backgroundColor: '#dbedfd' }}
                                                className={classes.button}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                                type="submit"
                                                size="large"
                                                className={classes.button}
                                                onClick={() => {
                                                    handleLangSave();
                                                }}
                                                disabled={languagesChipData.length === 0 || isApiCalling}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </MainCard>
                    {/* Edit Languages - ENDS */}
                </Modal>
            )}

            {editableField === ProfileEditableFields.ABOUT_ME && (
                <Modal open={showPopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {/* Edit About Me - STARTS */}
                    <MainCard className={classes.paper} content={false}>
                        <CardContent>
                            <div item xs={12}>
                                <h2 fullWidth style={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
                                    More About You
                                </h2>
                            </div>

                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="More about you"
                                        multiline
                                        minRows={5}
                                        maxRows={10}
                                        defaultValue=""
                                        size="small"
                                        value={tempProfileData.professional_experience_info}
                                        onChange={(event) => {
                                            let text = event.target.value;
                                            // Limit the input to 1000 characters
                                            text = text.slice(0, 1000);
                                            setTempProfileData({ ...tempProfileData, professional_experience_info: text });
                                        }}
                                        placeholder="More about you"
                                        error={tempProfileData.professional_experience_info.trim() === ''}
                                        sx={{
                                            mt: 2,
                                            maxHeight: '200px',
                                            '& .MuiOutlinedInput-input': {
                                                background: 'white'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ padding: '0px 0px 10px 0px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="text"
                                                type="button"
                                                onClick={dismissPopUp}
                                                style={{ backgroundColor: '#dbedfd' }}
                                                className={classes.button}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                                type="submit"
                                                size="large"
                                                className={classes.button}
                                                onClick={() => handleAboutSave()}
                                                disabled={tempProfileData.professional_experience_info.trim() === '' || isApiCalling}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </MainCard>
                    {/* Edit About me - ENDS */}
                </Modal>
            )}

            {editableField === ProfileEditableFields.CERTIFICATION && (
                <Modal open={showPopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {/* Edit Certification - STARTS */}
                    <MainCard className={classes.paper} content={false}>
                        <CardContent>
                            <div item xs={12}>
                                <h2 fullWidth style={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
                                    Edit Certification
                                </h2>
                            </div>

                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="course"
                                        name="course"
                                        label="Degree/Certificate/Courses"
                                        size="small"
                                        value={newCertificate.degree}
                                        onChange={(e) =>
                                            setNewCertificate((prev) => ({
                                                ...prev,
                                                degree: e.target.value
                                            }))
                                        }
                                        error={newCertificate.degree.trim() === ''}
                                        helperText={newCertificate.degree.trim() === '' ? 'Degree/Certificate/Courses cannot be empty' : ''}
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="areaOfStudy"
                                        name="areaOfStudy"
                                        label="Area of study"
                                        size="small"
                                        value={newCertificate.name}
                                        onChange={(e) =>
                                            setNewCertificate((prev) => ({
                                                ...prev,
                                                name: e.target.value
                                            }))
                                        }
                                        error={newCertificate.name.trim() === ''}
                                        helperText={newCertificate.name.trim() === '' ? 'Area of study cannot be empty' : ''}
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="institution"
                                        name="institution"
                                        label="Education institution"
                                        size="small"
                                        value={newCertificate.specialisation}
                                        onChange={(e) =>
                                            setNewCertificate((prev) => ({
                                                ...prev,
                                                specialisation: e.target.value
                                            }))
                                        }
                                        error={newCertificate.specialisation.trim() === ''}
                                        helperText={
                                            newCertificate.specialisation.trim() === '' ? 'Education institution cannot be empty' : ''
                                        }
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        options={years}
                                        getOptionLabel={(option) => option.toString()}
                                        renderInput={(params) => <TextField fullWidth {...params} label="From" />}
                                        size="small"
                                        id="from"
                                        value={newCertificate.started_at}
                                        onSelect={(e) =>
                                            setNewCertificate((prev) => ({
                                                ...prev,
                                                started_at: e.target.value
                                            }))
                                        }
                                        autoComplete="off"
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        options={years}
                                        getOptionLabel={(option) => option.toString()}
                                        renderInput={(params) => <TextField fullWidth {...params} label="To" />}
                                        size="small"
                                        id="to"
                                        value={newCertificate.finished_at}
                                        // onSelect={handleChange}
                                        autoComplete="off"
                                        onSelect={(e) =>
                                            setNewCertificate((prev) => ({
                                                ...prev,
                                                finished_at: e.target.value
                                            }))
                                        }
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="description"
                                        label="Description"
                                        multiline
                                        rows={3}
                                        defaultValue=""
                                        size="small"
                                        value={newCertificate.description}
                                        onChange={(e) =>
                                            setNewCertificate((prev) => ({
                                                ...prev,
                                                description: e.target.value
                                            }))
                                        }
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ padding: '0px 0px 10px 0px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="text"
                                                type="button"
                                                onClick={dismissPopUp}
                                                style={{ backgroundColor: '#dbedfd' }}
                                                className={classes.button}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                                type="submit"
                                                size="large"
                                                className={classes.button}
                                                onClick={handleUpdateCertificate}
                                                disabled={invalidCertificateData || isApiCalling}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </MainCard>
                    {/* Edit Certification - ENDS */}
                </Modal>
            )}
            {editableField === ProfileEditableFields.ADD_CERTIFICATION && (
                <Modal open={showPopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {/* Edit Certification - STARTS */}
                    <MainCard className={classes.paper} content={false}>
                        <CardContent>
                            <div item xs={12}>
                                <h2 fullWidth style={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
                                    Add Certification
                                </h2>
                            </div>

                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="course"
                                        name="course"
                                        label="Degree/Certificate/Courses"
                                        size="small"
                                        value={newCertificate.degree}
                                        onChange={(e) =>
                                            setNewCertificate((prev) => ({
                                                ...prev,
                                                degree: e.target.value
                                            }))
                                        }
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="areaOfStudy"
                                        name="areaOfStudy"
                                        label="Area of study"
                                        size="small"
                                        value={newCertificate.name}
                                        onChange={(e) =>
                                            setNewCertificate((prev) => ({
                                                ...prev,
                                                name: e.target.value
                                            }))
                                        }
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="institution"
                                        name="institution"
                                        label="Education institution"
                                        size="small"
                                        // inputProps={{ style: { textTransform: 'capitalize' } }}
                                        value={newCertificate.specialisation}
                                        onChange={(e) =>
                                            setNewCertificate((prev) => ({
                                                ...prev,
                                                specialisation: e.target.value
                                            }))
                                        }
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        options={years}
                                        getOptionLabel={(option) => option.toString()}
                                        renderInput={(params) => <TextField fullWidth {...params} label="From" />}
                                        size="small"
                                        id="from"
                                        value={newCertificate.started_at}
                                        onSelect={(e) =>
                                            setNewCertificate((prev) => ({
                                                ...prev,
                                                started_at: e.target.value
                                            }))
                                        }
                                        autoComplete="off"
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        options={years}
                                        getOptionLabel={(option) => option.toString()}
                                        renderInput={(params) => <TextField fullWidth {...params} label="To" />}
                                        size="small"
                                        id="to"
                                        value={newCertificate.finished_at}
                                        autoComplete="off"
                                        onSelect={(e) =>
                                            setNewCertificate((prev) => ({
                                                ...prev,
                                                finished_at: e.target.value
                                            }))
                                        }
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="description"
                                        label="Description"
                                        multiline
                                        rows={3}
                                        defaultValue=""
                                        size="small"
                                        value={newCertificate.description}
                                        onChange={(e) =>
                                            setNewCertificate((prev) => ({
                                                ...prev,
                                                description: e.target.value
                                            }))
                                        }
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ padding: '0px 0px 10px 0px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="text"
                                                type="button"
                                                onClick={dismissPopUp}
                                                style={{ backgroundColor: '#dbedfd' }}
                                                className={classes.button}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                                type="submit"
                                                size="large"
                                                className={classes.button}
                                                onClick={handleAddCertificate}
                                                disabled={invalidCertificateData || isApiCalling}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </MainCard>
                    {/* Edit Certification - ENDS */}
                </Modal>
            )}

            {editableField === ProfileEditableFields.PROJECTS && (
                <Modal open={showPopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {/* Edit Projects - STARTS */}
                    <MainCard className={classes.paper} content={false}>
                        <CardContent>
                            <div item xs={12}>
                                <h2 fullWidth style={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
                                    Edit Project
                                </h2>
                            </div>

                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth={true}
                                        id="role"
                                        name="role"
                                        label="Title/Position/Role"
                                        size="small"
                                        value={newProject.position}
                                        onChange={(e) =>
                                            setNewProject((prev) => ({
                                                ...prev,
                                                position: e.target.value
                                            }))
                                        }
                                        error={newProject?.position?.trim() === ''}
                                        helperText={newProject?.position?.trim() === '' ? 'Title/Position/Role cannot be empty' : ''}
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth={true}
                                        id="project"
                                        name="project"
                                        label="Company/Project"
                                        size="small"
                                        value={newProject.name}
                                        onChange={(e) =>
                                            setNewProject((prev) => ({
                                                ...prev,
                                                name: e.target.value
                                            }))
                                        }
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                        error={newProject?.name?.trim() === ''}
                                        helperText={newProject?.name?.trim() === '' ? 'Company/Project cannot be empty' : ''}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        options={years}
                                        getOptionLabel={(option) => option.toString()}
                                        renderInput={(params) => <TextField fullWidth={true} {...params} label="From" />}
                                        size="small"
                                        id="from"
                                        value={newProject?.started_at?.toString()}
                                        onSelect={(e) => {
                                            const updatedData = { ...newProject, started_at: e.target.value?.toString() };
                                            setNewProject(updatedData);
                                        }}
                                        autoComplete="off"
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        options={years}
                                        getOptionLabel={(option) => option.toString()}
                                        renderInput={(params) => <TextField fullWidth={true} {...params} label="To" />}
                                        size="small"
                                        id="to"
                                        value={newProject?.finished_at?.toString()}
                                        onSelect={(e) => {
                                            const updatedData = { ...newProject, finished_at: e.target.value?.toString() };
                                            setNewProject(updatedData);
                                        }}
                                        autoComplete="off"
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="country"
                                        value={newProject.country}
                                        onChange={(e, value) => {
                                            const updatedData = { ...newProject, country: value ?? '' };
                                            setNewProject(updatedData);
                                        }}
                                        options={Country}
                                        classes={{
                                            option: classes.option
                                        }}
                                        autoHighlight
                                        getOptionLabel={(option) => option}
                                        renderOption={(props, option) => <li {...props}>{option}</li>}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                                }}
                                                size="small"
                                                error={newProject?.country?.trim() === ''}
                                                helperText={newProject?.country?.trim() === '' ? 'Country cannot be empty' : ''}
                                            />
                                        )}
                                        size="small"
                                        autoComplete="off"
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth={true}
                                        id="city"
                                        label="City"
                                        rows={3}
                                        defaultValue=""
                                        size="small"
                                        value={newProject.city}
                                        onChange={(e) => {
                                            const updatedData = { ...newProject, city: e.target.value };
                                            setNewProject(updatedData);
                                        }}
                                        error={newProject?.city?.trim() === ''}
                                        helperText={newProject?.city?.trim() === '' ? 'City cannot be empty' : ''}
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth={true}
                                        id="description"
                                        label="Description"
                                        multiline
                                        rows={3}
                                        defaultValue=""
                                        size="small"
                                        value={newProject.description}
                                        onChange={(e) => {
                                            const updatedData = { ...newProject, description: e.target.value };
                                            setNewProject(updatedData);
                                        }}
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ padding: '0px 0px 10px 0px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="text"
                                                type="button"
                                                onClick={dismissPopUp}
                                                style={{ backgroundColor: '#dbedfd' }}
                                                className={classes.button}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                                type="submit"
                                                size="large"
                                                className={classes.button}
                                                onClick={() => handleProjectUpdate()}
                                                disabled={invalidProjectData || isApiCalling}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </MainCard>
                    {/* Edit Projects - ENDS */}
                </Modal>
            )}
            {editableField === ProfileEditableFields.ADD_PROJECT && (
                <Modal open={showPopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {/* Edit Projects - STARTS */}
                    <MainCard className={classes.paper} content={false}>
                        <CardContent>
                            <div item xs={12}>
                                <h2 fullWidth style={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
                                    Add Project
                                </h2>
                            </div>

                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth={true}
                                        id="role"
                                        name="role"
                                        label="Title/Position/Role"
                                        size="small"
                                        value={newProject.position}
                                        onChange={(e) =>
                                            setNewProject((prev) => ({
                                                ...prev,
                                                position: e.target.value
                                            }))
                                        }
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth={true}
                                        id="project"
                                        name="project"
                                        label="Company/Project"
                                        size="small"
                                        value={newProject.name}
                                        onChange={(e) =>
                                            setNewProject((prev) => ({
                                                ...prev,
                                                name: e.target.value
                                            }))
                                        }
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        options={years}
                                        renderInput={(params) => <TextField fullWidth={true} {...params} label="From" />}
                                        size="small"
                                        id="from"
                                        value={newProject.started_at?.toString()}
                                        onSelect={(e) => {
                                            const updatedData = { ...newProject, started_at: e.target.value?.toString() };
                                            setNewProject(updatedData);
                                        }}
                                        autoComplete="off"
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        options={years}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => <TextField fullWidth={true} {...params} label="To" />}
                                        size="small"
                                        id="to"
                                        value={newProject.finished_at?.toString()}
                                        onSelect={(e) => {
                                            const updatedData = { ...newProject, finished_at: e.target.value?.toString() };
                                            setNewProject(updatedData);
                                        }}
                                        autoComplete="off"
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="country"
                                        value={newProject.country}
                                        onChange={(e, value) => {
                                            const updatedData = { ...newProject, country: value };
                                            setNewProject(updatedData);
                                        }}
                                        options={Country}
                                        classes={{
                                            option: classes.option
                                        }}
                                        autoHighlight
                                        getOptionLabel={(option) => option}
                                        renderOption={(props, option) => <li {...props}>{option}</li>}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                                }}
                                                size="small"
                                            />
                                        )}
                                        size="small"
                                        autoComplete="off"
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth={true}
                                        id="city"
                                        label="City"
                                        rows={3}
                                        defaultValue=""
                                        size="small"
                                        value={newProject.city}
                                        onChange={(e) => {
                                            const updatedData = { ...newProject, city: e.target.value };
                                            setNewProject(updatedData);
                                        }}
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff',
                                                height: '40px'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth={true}
                                        id="description"
                                        label="Description"
                                        multiline
                                        rows={3}
                                        defaultValue=""
                                        size="small"
                                        value={newProject.description}
                                        onChange={(e) => {
                                            const updatedData = { ...newProject, description: e.target.value };
                                            setNewProject(updatedData);
                                        }}
                                        sx={{
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiInputBase-input': {
                                                backgroundColor: '#fff'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ padding: '0px 0px 10px 0px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="text"
                                                type="button"
                                                onClick={dismissPopUp}
                                                style={{ backgroundColor: '#dbedfd' }}
                                                className={classes.button}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                                type="submit"
                                                size="large"
                                                className={classes.button}
                                                onClick={handleProjectSave}
                                                disabled={invalidProjectData || isApiCalling}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </MainCard>
                    {/* Edit Projects - ENDS */}
                </Modal>
            )}

            {editableField === ProfileEditableFields.PROJECT_INTEREST && (
                <Modal open={showPopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {/* Edit Project Interests - STARTS */}
                    <MainCard className={classes.paper} content={false}>
                        <CardContent>
                            <div item xs={12}>
                                <h2 fullWidth style={{ textAlign: 'center', color: 'black', fontSize: '24px' }}>
                                    Select Project Interests
                                </h2>
                            </div>
                            {projInterestsArray.length == 0 && (
                                <Grid
                                    container
                                    direction="row"
                                    spacing={gridSpacing}
                                    sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', mt: 2, mb: 2, height: '100%' }}
                                >
                                    <CircularProgress />
                                </Grid>
                            )}
                            {projInterestsArray.length > 0 && (
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12} md={12}>
                                        <Box sx={{ background: '#F8F8F8', padding: 3, borderRadius: 3, width: '100%' }}>
                                            <Grid container spacing={2}>
                                                {projInterestsArray.map((interest, index) => (
                                                    <Grid key={index} item xs={12} md={12}>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                sx={{
                                                                    width: '100%',
                                                                    textAlign: 'left'
                                                                }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={interest.selected}
                                                                        sx={{
                                                                            color: '#59E239',
                                                                            '&.Mui-checked': {
                                                                                color: '#59E239'
                                                                            }
                                                                        }}
                                                                        onChange={(event) => {
                                                                            projectInterestSelectionUpdated(index);
                                                                        }}
                                                                        icon={<CircleUnchecked />}
                                                                        checkedIcon={<CheckCircleIcon />}
                                                                    />
                                                                }
                                                                componentsProps={{ typography: { color: 'black' } }}
                                                                label={interest.dv_project_types.name}
                                                            />
                                                        </FormGroup>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12} sx={{ padding: '0px 0px 10px 0px' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    variant="text"
                                                    type="button"
                                                    onClick={dismissPopUp}
                                                    style={{ backgroundColor: '#dbedfd' }}
                                                    className={classes.button}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    disableElevation
                                                    type="submit"
                                                    size="large"
                                                    className={classes.button}
                                                    disabled={
                                                        isApiCalling || projInterestsArray.filter((intst) => intst.selected).length == 0
                                                    }
                                                    onClick={handleProjectInterestUpdate}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </CardContent>
                    </MainCard>
                    {/* Edit Project Interests - ENDS */}
                </Modal>
            )}
        </Fragment>
    );
};

export default EditProfilePopups;
