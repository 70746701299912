import api from 'api';
import countryApi from 'api/countryApi';

//Add skills
export const addSkillApi = (filteredData, id) => {
    return api.post(`/user/specialist/add-skills/${id}`, { skills: filteredData });
};

//Add industry expereinces
export const addIndustryExperiencesApi = (filteredData, id) => {
    return api.post(`/user/specialist/industry/${id}`, { industry_ids: filteredData });
};

//Update industry title
export const updateTitleApi = async (title, id) => {
    return api.put(`/user/update-user/${id}`, {
        specialist: {
            industry_title: title
        }
    });
};

//Update experience level
export const updateExpLevelApi = async (expLevel, id) => {
    return api.post(`/user/specialist/experience-level/${id}`, {
        experience_level_id: expLevel
    });
};

//Update languages
export const updateLanguagesListApi = async (langList, id) => {
    return api.post(`/user/specialist/add-language/${id}`, {
        languages: langList
    });
};

//Update aboutme
export const updateAboutApi = async (about, id) => {
    return api.post(`/user/specialist/add-about/${id}`, {
        description: about
    });
};

//Update certificate
export const updateCertificateApi = async (certificateData, id) => {
    return api.post(`user/specialist/${id}/update-certificate/${certificateData.id}`, {
        degree: certificateData.degree,
        name: certificateData.name,
        description: certificateData.description,
        specialisation: certificateData.specialisation,
        started_at: certificateData.started_at,
        finished_at: certificateData.finished_at
    });
};

//Add certificate
export const addCertificateApi = async (certificate, id) => {
    return api.post(`user/specialist/add-certificate/${id}`, {
        certificate
    });
};

//Add project
export const addProjectApi = async (project, id) => {
    return api.post(`user/specialist/add-project/${id}`, {
        project
    });
};

//Update project
export const updateProjectApi = async (project, id) => {
    return api.post(`user/specialist/${id}/update-project/${project.id}`, {
        name: project.name,
        description: project.description,
        started_at: project.started_at,
        finished_at: project.finished_at,
        city: project.city,
        country: project.country,
        position: project.position
    });
};

//Update project interest
export const updateProjectInterestsApi = async (project_type, id) => {
    return api.post(`user/specialist/project-type/${id}`, {
        project_type
    });
};

//Update account details
export const updateAccountDetailsApi = async (data, id) => {
    return api.put(`user/specialist/update-account/${id}`, {
        first_name: data.first_name,
        last_name: data.last_name,
        phone_code: data.phone_code,
        phone_number: data.phone_number,
        country: data.country,
        state: data.state,
        city: data.suburb
    });
};

//Update billing profile
export const updateBillingAccountIDApi = async (billing_profile, id) => {
    return api.put(`user/specialist/update-account/${id}`, {
        billing_profile
    });
};

//Update billing details
export const updateBillingDetailsApi = async (data, id, billing_id) => {
    return api.put(`user/specialist/${id}/billing-account/${billing_id}/update`, {
        company_name: data.company_name,
        company_address: data.company_address,
        owner_name: data.owner_name,
        owner_email: data.owner_email,
        owner_phone: data.owner_phone,
        company_abn: data.company_abn,
        bank_name: data.bank_name,
        account_name: data.account_name,
        account_number: data.account_number,
        bsb_number: data.bsb_number,
        swift_code: data.swift_code,
        gst_vat: data.gst_vat
    });
};

//Create billing account
export const createNewBillingAccountApi = async (data, billing_id) => {
    return api.post(`user/billing-account/create`, {
        company_name: data.company_name,
        company_address: data.company_address,
        owner_name: data.owner_name,
        owner_email: data.owner_email,
        owner_phone: data.owner_phone,
        company_abn: data.company_abn,
        bank_name: data.bank_name,
        account_name: data.account_name,
        account_number: data.account_number,
        bsb_number: data.bsb_number,
        swift_code: data.swift_code,
        gst_vat: data.gst_vat
    });
};

export const getCountries = async () => {
    return countryApi.get(`countries`);
};

export const getStates = async (countryCode) => {
    return countryApi.get(`countries/${countryCode}/states`);
};

export const getCities = async (countryCode, stateCode) => {
    return countryApi.get(`countries/${countryCode}/states/${stateCode}/cities`);
};

// export const updateBilling = async (data, id, billing_id) => {
//     try {
//         const res = await api.put(`user/specialist/${id}/billing-account/${billing_id}/update`, data);

//         if (res.status !== 200) {
//             const errorData = {
//                 status: res.status,
//                 message: res.data.message
//             };
//             console.log(errorData);
//             return res; // Return the error data
//         }

//         return res; // Return the response data for successful update
//     } catch (error) {
//         console.error('Error updating billing:', error);
//         throw error; // Throw the error to be caught by the caller
//     }
// };
