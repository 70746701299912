import React from 'react';

const LeftRightAlignWrapper = ({ children }) => {
    const containerStyles = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    };

    return <div style={containerStyles}>{children}</div>;
};

export default LeftRightAlignWrapper;
