import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { PROJECT_LEVEL_ROLE } from 'store/constant';
import NewProfile from 'views/pages/profile/new-profile/NewProfile';

// application - user social & account profile routing
const AppUserSocialProfile = Loadable(lazy(() => import('views/pages/profile/social-profile')));

// application - user cards & list variant routing
const AppProfileNetwork = Loadable(lazy(() => import('views/pages/profile/profile-network/ProfileNetwork')));
const AppProfileNetworkDetail = Loadable(lazy(() => import('views/pages/profile/profile-network/ProfileNetworkDetail')));
const AppProfileTeams = Loadable(lazy(() => import('views/pages/profile/profile-teams/ProfileTeams')));
const AppProfileTeamDetail = Loadable(lazy(() => import('views/pages/profile/profile-teams/ProfileTeamDetail')));
const AppProfileProjects = Loadable(lazy(() => import('views/pages/profile/profile-projects/ProfileProjects')));
const AppSharedProjects = Loadable(lazy(() => import('views/pages/profile/profile-projects/SharedProjects')));
const AppDigitalVillage = Loadable(lazy(() => import('views/pages/profile/project-details/DigitalVillage')));
const EditAccount = Loadable(lazy(() => import('views/pages/profile/edit-account/EditAccount')));
const Dashboard = Loadable(lazy(() => import('views/pages/profile/dashboard/Dashboard')));

//reports
const ProjectReport = Loadable(lazy(() => import('views/pages/project-report')));

// ===========================|| MAIN ROUTING ||=========================== //
const userType = window.localStorage.getItem('dv_user_type');
const normalUserRoutes = [
    {
        path: '/profile/dashboard',
        element: <Dashboard />
    },
    {
        path: '/profile/profile',
        element: <NewProfile />
    },
    {
        path: '/profile/old-profile',
        element: <AppUserSocialProfile />
    },
    {
        path: '/profile/profile-network/network',
        element: <AppProfileNetwork />
    },
    {
        path: '/profile/profile-network/network-detail',
        element: <AppProfileNetworkDetail />
    },
    {
        path: '/profile/profile-network/network-detail/:networkId/:teamDet1',
        element: <AppProfileNetworkDetail />
    },
    {
        path: '/profile/profile-teams/teams',
        element: <AppProfileTeams />
    },
    {
        path: '/profile/profile-teams/team-detail',
        element: <AppProfileTeamDetail />
    },
    {
        path: '/profile/profile-projects/my-projects',
        element: <AppProfileProjects />
    },
    {
        path: '/profile/profile-projects/shared-projects',
        element: <AppSharedProjects />
    },
    {
        path: '/profile/project/details',
        element: <AppDigitalVillage />
    },
    {
        path: '/profile/edit-account',
        element: <EditAccount />
    }
];
const adminUserRoutes = [
    {
        path: '/profile/profile-network/network',
        element: <AppProfileNetwork />
    },
    {
        path: '/profile/profile-network/network-detail',
        element: <AppProfileNetworkDetail />
    },
    {
        path: '/profile/profile-network/network-detail/:networkId/:teamDet1',
        element: <AppProfileNetworkDetail />
    },
    {
        path: '/profile/profile-teams/teams',
        element: <AppProfileTeams />
    },
    {
        path: '/profile/profile-teams/team-detail',
        element: <AppProfileTeamDetail />
    },
    {
        path: '/profile/profile-projects/my-projects',
        element: <AppProfileProjects />
    },

    {
        path: '/profile/project/details',
        element: <AppDigitalVillage />
    },
    {
        path: '/report',
        element: <ProjectReport />
    }
];

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: userType == PROJECT_LEVEL_ROLE.admin ? adminUserRoutes : normalUserRoutes
};

export default MainRoutes;
