import { Navigate, json, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Alert, Stack } from '@material-ui/core';
import config from 'config';

const api = axios.create({
    baseURL: `${config.restAPI.baseURL}/`,
    headers: {
        'Content-Type': 'application/json'
    }
});
let error = true;
// const getToken = () => {
//   const local = JSON.parse(localStorage.getItem("state"));
//   const token = local?.Login?.login?.data?.access;
//   return token;
// };
let token = window.localStorage.getItem('access_token');
if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// if (error) {
//     return (
//       <Stack sx={{ width: "100%" }} spacing={2}>
//         <Alert severity="error">{error.message}</Alert>
//       </Stack>
//     );
//   }

let isRefreshing = false;
let failedRequestsQueue = [];
api.interceptors.response.use(
    (response) => {
        const responseData = response;
        console.log(responseData, 'resdata_____');
        return responseData;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response) {
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                if (!isRefreshing) {
                    isRefreshing = true;
                    try {
                        // Get the new access token
                        const newToken = await token;

                        // Set the new access token in the API interceptor
                        api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

                        // Retry the request with the new access token
                        return api(originalRequest);
                    } catch (refreshError) {
                        // Handle refresh token error
                        const navigate = useNavigate();
                        localStorage.clear();
                        navigate('/');
                    }
                } else {
                    // Add the failed request to the queue
                    failedRequestsQueue.push(originalRequest);
                }
            }
            if (error.response.status === 500) {
                error = true;
                const navigate = useNavigate();
                navigate('/');
                return error.response;
            }
            if (error.response.status === 400) {
                error = true;
                return error.response;
                // const errorTrue = useStore.getState().errorTrue;
                // errorTrue();
            }
            if (error.response.status === 404) {
                error = true;
                return error.response;
                // const navigate = useNavigate();
                // navigate("/page-not-found");
                // window.location.href = window.location.pathname + '/page-not-found'
            }
            if (error.response.status === 403) {
                console.log('error 403________________');
                return error.response;
            }
        } else if (error.request) {
        } else {
            console.log('error 403________________');
            return (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">This is an error alert — check it out!</Alert>
                </Stack>
            );
            // throw new ApiError('An unknown error occurred.');
        }
        if (error.code === 'ECONNABORTED') {
            // Handle timeout error here
        }
        return Promise.reject(error);
    }
);

export default api;
