import React, { useState, useRef } from 'react';
import { Box, Button, Slider, Typography } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

const AudioCard = (props) => {
    const { audioURL } = props;
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [mute, setMute] = useState(false);
    const [progress, setProgress] = useState(0);

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const handleTimeUpdate = () => {
        const currentTime = audioRef.current.currentTime;
        const duration = audioRef.current.duration;
        const calculatedProgress = (currentTime / duration) * 100;
        setProgress(calculatedProgress);
    };

    const handleSeek = (_, newValue) => {
        const newTime = (newValue * audioRef.current.duration) / 100;
        audioRef.current.currentTime = newTime;
        setProgress(newValue);
    };
    const buttonStyle = () => ({
        color: '#fff',
        fontSize: '40px',
        background: '#5e35b1',
        position: 'inherit',
        borderRadius: '50%',
        padding: 'inherit',
        minWidth: 'inherit',
        width: 48,
        height: 48,
        '&:hover': {
            backgroundColor: '#5e35b1'
        }
    });

    return (
        <Box
            sx={{
                height: 100,
                backgroundColor: '#F5F0FF',
                borderRadius: 3,
                display: 'flex',
                alignItems: 'center',
                padding: '0px 20px',
                gap: '10px',
                cursor: 'pointer',
                marginTop: '10px'
            }}
        >
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Button sx={buttonStyle} onClick={togglePlayPause}>
                    {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                </Button>
                <Button onClick={() => setMute((prev) => !prev)}>
                    {mute ? <VolumeOffIcon sx={{ color: '#B1B1B1' }} /> : <VolumeUpIcon sx={{ color: '#B1B1B1' }} />}
                </Button>
                {audioRef.current && audioRef.current.duration ? (
                    <Typography sx={{ marginRight: 2, color: '#7439E2', fontWeight: 600 }}>
                        {formatTime(audioRef.current.currentTime)}
                    </Typography>
                ) : (
                    <Typography sx={{ marginRight: 2, color: '#7439E2', fontWeight: 600 }}>00:00</Typography>
                )}
                <Slider
                    value={progress}
                    onChange={handleSeek}
                    aria-labelledby="continuous-slider"
                    sx={{ width: '65%', color: '#5e35b1' }}
                />
                {audioRef.current && audioRef.current.duration ? (
                    <Typography sx={{ marginLeft: 2, color: '#141414', fontWeight: 600 }}>
                        {formatTime(audioRef.current.duration)}
                    </Typography>
                ) : (
                    <Typography sx={{ marginLeft: 2, color: '#141414', fontWeight: 600 }}>00:00</Typography>
                )}
                <audio
                    ref={audioRef}
                    src={audioURL}
                    muted={mute}
                    style={{ display: 'none' }}
                    onTimeUpdate={handleTimeUpdate}
                    onEnded={() => setIsPlaying(false)}
                />
            </Box>
        </Box>
    );
};

export default AudioCard;
