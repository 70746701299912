import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    Typography,
    CardContent,
    Backdrop,
    Box,
    Stack,
    Grid,
    FormGroup,
    FormControlLabel,
    Button,
    TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
//Utils
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import axios from 'axios';
import config from 'config';

// style constant
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 600,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
            width: '94%',
            maxHeight: '100%',
            overflow: 'scroll'
        }
    }
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    bgcolor: 'white',
    borderRadius: 3,
    boxShadow: 30,
    p: 2
};

const EditAdmiredBrands = (props) => {
    const { open, profileData, questionData, closePopup } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const accessToken = window.localStorage.getItem('access_token');
    const userId = window.localStorage.getItem('user_id');
    const [isApiCalling, setIsApiCalling] = useState(false);
    const [userInput, setUserInput] = useState('');

    const showSnackbar = (message, variant) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: variant,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            close: true
        });
    };

    //MARK: APIs
    const saveDataApi = async (event) => {
        setIsApiCalling(true);
        try {
            const data = {
                contact: {
                    answers: [
                        {
                            answer_id: '',
                            created_at: new Date().toISOString(),
                            input_text: userInput,
                            is_public: false,
                            poll_options: [],
                            file_uploads: [],
                            question_id: questionData.questionID,
                            share_id: '',
                            share_url: '',
                            type: 'text'
                        }
                    ],
                    email: profileData.email
                }
            };

            // Make a POST request to your backend endpoint handling avatar uploads
            const response = await axios.post(`${config.restAPI.baseURL}/user/videoask/update`, data, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            });
            showSnackbar('Profile updated successfully', 'success');
            setIsApiCalling(false);
            closePopup();
        } catch (error) {
            console.error('updation failed:', error);
            showSnackbar('Profile updation failed', 'error');
            setIsApiCalling(false);
        }
    };

    return (
        <Fragment>
            <Modal
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
            >
                <Box sx={{ ...style }}>
                    <CardContent>
                        <Stack direction={'row'} alignContent={'center'} display={'flex'} justifyContent={'space-between'}>
                            <Typography id="modal-modal-title" variant="h2" component="h2" mb={2}>
                                Admired Brands or Companies
                            </Typography>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth multiline minRows={5} maxRows={10} onChange={(e) => setUserInput(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            variant="text"
                                            type="button"
                                            onClick={closePopup}
                                            style={{ backgroundColor: '#dbedfd' }}
                                            className={classes.button}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            disableElevation
                                            type="submit"
                                            size="large"
                                            className={classes.button}
                                            disabled={isApiCalling || userInput.trim() === ''}
                                            onClick={saveDataApi}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Box>
            </Modal>
        </Fragment>
    );
};

EditAdmiredBrands.propTypes = {
    open: PropTypes.bool,
    profileData: PropTypes.object,
    questionData: PropTypes.object,
    closePopup: PropTypes.func
};

export default EditAdmiredBrands;
