import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Box, Button, CardMedia, Divider, Grid, Tab, Tabs, Typography, Stack, CircularProgress } from '@material-ui/core';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent
} from '@material-ui/lab';
import { TextField, FormHelperText, Modal, CardContent, IconButton } from '@material-ui/core';

//MUI - Icons
import CloseIcon from '@material-ui/icons/Close';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PersonIcon from '@mui/icons-material/Person';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import RoomIcon from '@mui/icons-material/Room';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';

//Consts
import ScrollspyNav from 'react-scrollspy-nav';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { gridSpacing } from 'store/constant';

//Utils
import axios from 'axios';
import config from 'config';
import * as Yup from 'yup';
import { Formik } from 'formik';

//Custom Components
import Cover from 'assets/images/profile/img-profile-bg.png';
import Loader from 'ui-component/Loader';
import VideoCard from 'ui-component/cards/VideoCard';
import AudioCard from 'ui-component/cards/AudioCard';
import DeleteButtonProfile from 'ui-component/DeleteButtonProfile';
import EditButtonProfile from 'ui-component/EditButtonProfile';
import Avatar from 'ui-component/extended/Avatar';
import MainCard from 'ui-component/cards/MainCard';
import ImagePlaceholder from 'ui-component/cards/Skeleton/ImagePlaceholder';
import LeftRightAlignWrapper from 'ui-component/LeftRightAlignWrapper';

//Edit profile
import EditProfilePopups from '../edit-account/EditProfilePopups';
import { ProfileEditableFields } from '../edit-account/ProfileEditableFields';
import NodataCard from 'ui-component/NodataCard';
import VideoAskPopup from './VideoAskPopup';
import EditPreferredPractitioner from './EditPreferredPractitioner';
import EditTypesOfClients from './EditTypesOfClients';
import EditAdmiredBrands from './EditAdmiredBrands';

// style constant
const useStyles = makeStyles((theme) => ({
    userTopContent: {
        padding: '12px',
        paddingBottom: '0px !important',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    paper: {
        position: 'absolute',
        width: 600,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
            width: '94%',
            maxHeight: '100%',
            overflow: 'scroll'
        }
    },

    userAvatar: {
        margin: '-75px 0 0 auto',
        borderRadius: '16px',
        [theme.breakpoints.down('md')]: {
            margin: '-70px auto 0'
        },
        [theme.breakpoints.down('sm')]: {
            margin: '-60px auto 0'
        },
        boxShadow: theme.shadows[5]
    },
    userProfileTabs: {
        marginTop: '40px',
        '& .MuiTabs-flexContainer': {
            border: 'none'
        },
        '& a': {
            minHeight: 'auto',
            minWidth: '10px',
            padding: '12px 8px',
            marginRight: '18px',
            color: theme.palette.grey[700],
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& a.Mui-selected': {
            color: theme.palette.primary.main
        },
        '& a > svg': {
            marginBottom: '4px !important',
            marginRight: '10px'
        }
    },
    sideAvatarIcon6: {
        borderRadius: '8px',
        width: '48px',
        height: '48px',
        fontSize: '1.5rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#FFF8E1',
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: '#FFC107',
        color: '#FFC107',
        '&>svg': {
            width: '24px',
            height: '24px'
        }
    },
    editIcon: {
        borderRadius: '8px',
        width: '48px',
        height: '48px',
        fontSize: '1.5rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: '#707070',
        color: '#707070',
        '&>svg': {
            width: '24px',
            height: '24px'
        }
    },
    sideAvatarIcon5: {
        borderRadius: '8px',
        width: '48px',
        height: '48px',
        fontSize: '1.5rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#F5F5F5',
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: '#707070',
        color: '#707070',
        '&>svg': {
            width: '24px',
            height: '24px'
        }
    },
    sideAvatarIcon4: {
        borderRadius: '8px',
        width: '48px',
        height: '48px',
        fontSize: '1.5rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#FFEBEB',
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: '#FF6F6F',
        color: '#FF6F6F',
        '&>svg': {
            width: '24px',
            height: '24px'
        }
    },
    sideAvatarIcon3: {
        borderRadius: '8px',
        width: '48px',
        height: '48px',
        fontSize: '1.5rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#DEFFEB',
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: '#19CE3C',
        color: '#19CE3C',
        '&>svg': {
            width: '24px',
            height: '24px'
        }
    },
    sideAvatarIcon2: {
        borderRadius: '8px',
        width: '48px',
        height: '48px',
        fontSize: '1.5rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.mode === 'dark' ? theme.palette.secondary.main + 20 : theme.palette.secondary.light,
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.dark,
        '&>svg': {
            width: '24px',
            height: '24px'
        }
    },
    sideAvatarIcon1: {
        borderRadius: '8px',
        width: '48px',
        height: '48px',
        fontSize: '1.5rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.mode === 'dark' ? theme.palette.primary.main + 20 : theme.palette.primary.light,
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.dark,
        '&>svg': {
            width: '24px',
            height: '24px'
        }
    },
    sidebarDivider: {
        margin: '16px 0'
    },
    editViewProfileButton: {
        width: '85px',
        borderRadius: 8,
        fontSize: '16px',
        fontWeight: 'medium'
    }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const tabOptions = [
    {
        to: '#basic-info',
        label: 'Personal Details'
    },
    {
        to: '#skills',
        label: 'Skills'
    },
    {
        to: '#certificate',
        label: 'Certifications'
    },
    {
        to: '#projects',
        label: 'Projects'
    },
    {
        to: '#projectinterest',
        label: 'Project Interests'
    }
];

function dateRangeString(start, end) {
    if (start && end) {
        return `${start} to ${end}`;
    }
    if (start && !end) {
        return `${start} to Present`;
    }
    return '';
}

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

// ===========================|| SOCIAL PROFILE ||=========================== //

const NewProfile = () => {
    // const accessToken = window.localStorage.getItem('access_token');
    // const userId = window.localStorage.getItem('user_id');
    const selectedTab = 0;
    const [value, setValue] = useState(selectedTab);
    const classes = useStyles();
    const customization = useSelector((state) => state.customization);
    const userRoles = useSelector((state) => state.userRole);

    const accessToken = window.localStorage.getItem('access_token');
    const userId = window.localStorage.getItem('user_id');

    const { tab } = useParams();
    // const [value, setValue] = useState(selectedTab);
    const [profileData, setProfileData] = useState({});
    const [updated, setUpdated] = useState(false);
    const [experienceLevel, setExperienceLevel] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [isProfileEditing, setIsProfileEditing] = useState(false);
    const [selectedProject, setSelectedProject] = useState({});
    const [showAllSkills, setShowAllSkills] = useState(false);
    const [modalStyle] = useState(getModalStyle);
    const [openBillingPopup, setOpenBillingPopup] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState({});

    const dispatch = useDispatch();

    //edit profile
    const [showEditProfilePopup, setShowEditProfilePopup] = useState(false);
    const [profileEditableField, setProfileEditableField] = useState(null);

    //Video ask questions
    const [selectedVideoAskIndex, setSelectedVideoAskIndex] = useState(null);
    const [editPrefferedPractitioner, setEditPreferredPractitioner] = useState(false);
    const [editTypesOfClients, setEditTypesOfClients] = useState(false);
    const [editAdmiredBrands, setEditAdmiredBrands] = useState(false);
    const [videoAskApiData, setVideoAskApiData] = useState([]);
    const [videoAskResponses, setVideoAskResponses] = useState([
        {
            questionID: '493e3095-c070-498d-bf71-64bc480c3789',
            questionTitle: 'Preferred Practioner Group',
            type: 'poll',
            response: null,
            token: ''
        },
        {
            questionID: '02b18d8b-1e9f-43b6-a8ed-a82cd52995d4',
            questionTitle: 'Types of Client',
            type: 'poll',
            response: null,
            token: ''
        },
        { questionID: 'd9ca4970-177f-4fb0-877d-e16a7551c2c9', questionTitle: 'About me', type: '', response: null, token: 'f0az5gdsw' },
        {
            questionID: 'c9ffae58-768a-4950-ac62-9188a4f0bd6f',
            questionTitle: 'Work Showcase',
            type: '',
            response: null,
            token: 'flwaccbgs'
        },
        {
            questionID: 'c85db920-a8b3-4889-9e09-82622279278f',
            questionTitle: 'How People Describe Me',
            type: '',
            response: null,
            token: 'fqhryzdlh'
        },
        { questionID: '3b119256-5872-421b-a004-e2205cf0c5cd', questionTitle: 'Work Style', type: '', response: null, token: 'fpl08r2xd' },
        {
            questionID: '120e1018-2d5f-4d9f-8a56-9a0ffb07cdfc',
            questionTitle: 'Tools & Technologies',
            type: '',
            response: null,
            token: 'fc5pvmw5d'
        },
        {
            questionID: '2ff12147-7710-41e1-8111-4156c1044552',
            questionTitle: 'Ideal Project / Client Profile',
            type: '',
            response: null,
            token: 'fzjhhx8ww'
        },
        {
            questionID: '2c4304e1-66ea-45c2-b026-793db19bfceb',
            questionTitle: 'Understanding of Meaningful Work',
            type: '',
            response: null,
            token: 'frlhhb1lv'
        },
        {
            questionID: '27b31dc6-67a0-499b-b6a2-49b1fbcd57db',
            questionTitle: 'Admired Brands or Companies',
            type: '',
            response: null,
            token: ''
        }
    ]);

    //MARK: FUNCTIONS
    const deleteVideoAskContent = (index) => {
        if (index < videoAskResponses.length) {
            let videoAskData = [...videoAskResponses];
            let questionID = videoAskData[index].questionID;
            videoAskData[index].response = null;
            setVideoAskResponses([...videoAskData]);

            let video_ask = videoAskApiData.filter((item) => item.question_id !== questionID);
            removeVideoAskContent(video_ask);
        }
    };

    const addVideoAskContent = (index) => {
        if (index < videoAskResponses.length) {
            setSelectedVideoAskIndex(index);
        }
    };

    const closeVideoAskPopUp = () => {
        setEditPreferredPractitioner(false);
        setEditTypesOfClients(false);
        setEditAdmiredBrands(false);
        setSelectedVideoAskIndex(null);
        getUserProfile();
    };
    const showEditPopup = (editableField) => {
        setProfileEditableField(editableField);
        setShowEditProfilePopup(true);
    };

    const dismissEditProfilePopup = () => {
        setProfileEditableField(null);
        setShowEditProfilePopup(false);
    };

    const showSnackbar = (message, variant) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message,
            variant: 'alert',
            alertSeverity: variant,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            close: true
        });
    };

    const getInitials = (text) => {
        // Trim the input text to remove leading and trailing spaces
        text = text.trim();

        if (text == '') {
            return '';
        } else {
            // Split the trimmed text into an array of words
            const words = text.split(' ');

            // Initialize variables to store initials
            let firstInitial = '';
            let secondInitial = '';

            // Check if there's at least one word
            if (words.length > 0) {
                // Extract the first character of the first word as the first initial
                firstInitial = words[0][0].toUpperCase();

                // Check if there's a second word available
                if (words.length > 1) {
                    // Extract the first character of the second word as the second initial
                    secondInitial = words[1][0].toUpperCase();
                } else if (words[0].length > 1) {
                    // If there's no second word but the first word has more than one character,
                    // use the second character of the first word as the second initial
                    secondInitial = words[0][1].toUpperCase();
                }
            }

            // Return the concatenated initials
            return firstInitial + secondInitial;
        }
    };
    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setOpenBillingPopup(false);
    };

    const handleEditBilling = () => {
        setOpenBillingPopup(true);
    };

    const handleShowMoreSkills = () => {
        setShowAllSkills((prev) => !prev);
    };

    //MARK: APIs
    const uploadProfileImage = async (event) => {
        const selectedImage = event.target.files[0];
        try {
            const formData = new FormData();
            formData.append('avatar', selectedImage);

            // Make a POST request to your backend endpoint handling avatar uploads
            const response = await axios.post(`${config.restAPI.baseURL}/user/specialist/add-avatar/${profileData.id}`, formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            });
            showSnackbar('Profile image updated successfully', 'success');
            getUserProfile();
            // Handle success, e.g., update UI or display a success message
        } catch (error) {
            console.error('Upload failed:', error);
            showSnackbar('Profile updation failed', 'error');
            // Handle error, e.g., show error message to the user
        }
    };

    const removeVideoAskContent = async (data) => {
        try {
            const response = await axios.put(
                `${config.restAPI.baseURL}/user/specialist/update-videoask/${profileData.id}`,
                { video_ask: data },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );
            showSnackbar('Profile updated successfully', 'success');
            getUserProfile();
            // Handle success, e.g., update UI or display a success message
        } catch (error) {
            console.error('Updation failed:', error);
            showSnackbar('Profile updation failed', 'error');
            // Handle error, e.g., show error message to the user
        }
    };

    const getUserProfile = async () => {
        try {
            const userResponse = await axios.get(`${config.restAPI.baseURL}/user/specialist/${userId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json'
                }
            });

            const userData = userResponse.data.data;
            setProfileData(userData);
            //manage video ask contents
            const videoAskData = userData.video_ask ?? null;

            if (videoAskData !== null) {
                const updatedResponses = videoAskResponses.map((response) => {
                    const userAnswer = videoAskData.find((data) => data.question_id === response.questionID);
                    if (userAnswer) {
                        response.type = userAnswer.type;

                        switch (userAnswer.type) {
                            case 'poll':
                                response.response = userAnswer.poll_options.map((option) => option.content);
                                break;
                            case 'video':
                                response.response = { videoURL: userAnswer.media_url, thumbnail: userAnswer.thumbnail };
                                break;
                            case 'audio':
                                response.response = { audioURL: userAnswer.media_url };
                                break;
                            default:
                                // text
                                response.response = userAnswer.input_text;
                        }
                    }
                    return response;
                });
                console.log('🚀 ~ file: NewProfile.js:493 ~ updatedResponses ~ updatedResponses:', updatedResponses);
                setVideoAskResponses(updatedResponses);
                setVideoAskApiData(videoAskData);
            }
            localStorage.setItem('profile_data', JSON.stringify(userData));

            if (userData.experience_level_id) {
                switch (userData.experience_level_id) {
                    case 1:
                        setExperienceLevel('Beginner');
                        break;
                    case 2:
                        setExperienceLevel('Mid-weight');
                        break;
                    case 3:
                        setExperienceLevel('Experienced');
                        break;
                    default:
                        setExperienceLevel('');
                }
            }

            setLoading(false);
        } catch (error) {
            console.error(error);
            // showSnackbar('Failed to load the profile details', 'error');
        }
    };

    const deleteCertificateAction = async (certificate) => {
        try {
            const deleteCertificateResponse = await axios.delete(
                `${config.restAPI.baseURL}/user/certification/delete/${userId}/${certificate.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        Accept: 'application/json'
                    }
                }
            );
            showSnackbar('Certificate deleted successfully', 'success');
            getUserProfile();
        } catch (error) {
            console.error(error);
            showSnackbar('Certificate deletion failed', 'error');
        }
    };

    const deleteProjectAction = async (project) => {
        try {
            const deleteProjectResponse = await axios.delete(`${config.restAPI.baseURL}/user/experience/delete/${userId}/${project.id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json'
                }
            });
            showSnackbar('Project deleted successfully', 'success');
            getUserProfile();
        } catch (error) {
            console.error(error);
            showSnackbar('Project deletion failed', 'error');
        }
    };

    //MARK: USE EFFECT
    useEffect(() => {
        getUserProfile();
        // console.log('🚀 ~ file: index.js:440 ~ useEffect ~ userRoles:', userRoles);
        // dispatch({ type: ROLE_ADMIN });
        // // console.log('🚀 ~ file: index.js:440 ~ useEffect ~ userRoles:', userRoles);
    }, [updated]);

    useEffect(() => {
        getUserProfile();
    }, []);

    if (isLoading) {
        return (
            <Fragment>
                <Loader />
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', mt: 2, mb: 2, height: '400px' }}
                >
                    <CircularProgress />
                </Grid>
            </Fragment>
        );
    }

    //Skills Chips
    const initialDisplayCount = 6;
    const displayCount = showAllSkills ? profileData.specialist_skills.length : initialDisplayCount;
    return (
        <Fragment>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <MainCard contentClass={classes.userTopContent}>
                        {isLoading ? (
                            <ImagePlaceholder
                                sx={{
                                    borderRadius: `${customization.borderRadius}px`,
                                    overflow: 'hidden',
                                    mb: 3,
                                    height: { xs: '85px', sm: '150px', md: '260px' }
                                }}
                            />
                        ) : (
                            <CardMedia
                                component="img"
                                image={Cover}
                                sx={{ borderRadius: `${customization.borderRadius}px`, overflow: 'hidden', mb: 3 }}
                            />
                        )}
                        <Stack direction={'row'}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} md={3} lg={2}>
                                    {isLoading ? (
                                        <ImagePlaceholder
                                            className={classes.userAvatar}
                                            sx={{
                                                width: { xs: '72px', sm: '100px', md: '130px' },
                                                height: { xs: '72px', sm: '100px', md: '130px' }
                                            }}
                                        />
                                    ) : (
                                        <Stack direction="column" alignItems="center" sx={{ position: 'relative' }}>
                                            <Avatar
                                                src={profileData?.avatar}
                                                className={classes.userAvatar}
                                                sx={{
                                                    border: 5,
                                                    borderColor: '#FFFFFF !important',
                                                    width: { xs: '72px', sm: '100px', md: '130px' },
                                                    height: { xs: '72px', sm: '100px', md: '130px' }
                                                }}
                                            />
                                            {isProfileEditing && (
                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    sx={{
                                                        zIndex: 10,
                                                        width: '35px',
                                                        height: '35px',
                                                        backgroundColor: '#0094FF',
                                                        border: '3px solid #fff',
                                                        boxShadow: '0px 0px 10px #00000029',
                                                        position: 'absolute', // Position button absolutely within the stack container
                                                        bottom: '-20px', // Align button to the bottom
                                                        transform: 'translateX(-50%)', // Center button horizontally
                                                        left: '85%', // Center button horizontally
                                                        '&:hover': {
                                                            backgroundColor: '#0094FF',
                                                            boxShadow: '0px 2px 8px #00000070'
                                                        }
                                                    }}
                                                >
                                                    <label htmlFor="upload-avatar" style={{ cursor: 'pointer' }}>
                                                        <input
                                                            accept="image/*"
                                                            id="upload-avatar"
                                                            type="file"
                                                            style={{ display: 'none' }}
                                                            onChange={uploadProfileImage}
                                                        />
                                                        <AddIcon sx={{ color: '#fff' }} />
                                                    </label>
                                                </IconButton>
                                            )}
                                        </Stack>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={9} lg={10}>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant="h5" sx={{ fontSize: '16px' }}>{`${profileData?.first_name ?? '--'} ${
                                                profileData?.last_name ?? ''
                                            }`}</Typography>
                                            <Typography variant="subtitle2" sx={{ fontSize: '14px', color: '#707070', lineHeight: '30px' }}>
                                                {`${profileData?.industry_title ?? ''}`}
                                                {isProfileEditing && (
                                                    <EditButtonProfile
                                                        sx={{ ml: 1 }}
                                                        buttonTapped={() => showEditPopup(ProfileEditableFields.INDUSTRY_TITLE)}
                                                    />
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box sx={{ width: '100px' }}>
                                {!isLoading && !isProfileEditing && (
                                    <Button
                                        startIcon={<EditIcon />}
                                        // onClick={}
                                        className={classes.editViewProfileButton}
                                        variant="outlined"
                                        disableElevation
                                        sx={{ mr: 2 }}
                                        onClick={() => setIsProfileEditing(true)}
                                    >
                                        Edit
                                    </Button>
                                )}
                                {!isLoading && isProfileEditing && (
                                    <Button
                                        // onClick={}
                                        className={classes.editViewProfileButton}
                                        sx={{ mr: 2 }}
                                        variant="outlined"
                                        disableElevation
                                        onClick={() => setIsProfileEditing(false)}
                                    >
                                        View
                                    </Button>
                                )}
                            </Box>
                        </Stack>

                        <ScrollspyNav
                            scrollTargetIds={['basic-info', 'certificate', 'projects', 'projectinterest']}
                            activeNavClass="is-active"
                            scrollDuration="1000"
                            headerBackground="true"
                            className="tab_ul"
                        >
                            <Grid container justifyContent="flex-end">
                                <Tabs
                                    tabItemContainerStyle={{ position: 'fixed', bottom: '0' }}
                                    value={value}
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    onChange={handleChange}
                                    className={classes.userProfileTabs}
                                >
                                    {tabOptions.map((item, index) => (
                                        <LinkTab
                                            key={index}
                                            href={item.to}
                                            icon={item.icon}
                                            label={item.label}
                                            {...a11yProps(index)}
                                            onContextMenu={(e) => e.preventDefault()}
                                        />
                                    ))}
                                </Tabs>
                            </Grid>
                        </ScrollspyNav>
                    </MainCard>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Box sx={{ marginBottom: 3 }}>
                                        <MainCard>
                                            {/* Preferred Practitioner Group (Video Ask) */}
                                            {(isProfileEditing || videoAskResponses[0].response !== null) && (
                                                <Grid container alignItems="center" spacing={gridSpacing}>
                                                    <Grid item sx={{ width: '100%' }}>
                                                        <LeftRightAlignWrapper>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{ fontSize: '20px', fontWeight: 'medium', textAlign: 'left' }}
                                                            >
                                                                Preferred Practitioner Group
                                                            </Typography>

                                                            {isProfileEditing && videoAskResponses[0].response !== null && (
                                                                <DeleteButtonProfile actionConfirmed={() => deleteVideoAskContent(0)} />
                                                            )}
                                                        </LeftRightAlignWrapper>
                                                        {videoAskResponses[0].response !== null && (
                                                            <Box sx={{ paddingTop: 2, paddingBottom: 1 }}>
                                                                <Grid item sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                                                    {videoAskResponses[0].response.map((item, index) => {
                                                                        return (
                                                                            <Typography
                                                                                key={index}
                                                                                variant="h1"
                                                                                sx={{
                                                                                    display: 'inline-flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    fontSize: '12px',
                                                                                    fontWeight: 'medium',
                                                                                    background: '#F5F5F5',
                                                                                    height: 'auto',
                                                                                    borderRadius: '5px',
                                                                                    padding: '10px 16px'
                                                                                }}
                                                                            >
                                                                                {item}
                                                                            </Typography>
                                                                        );
                                                                    })}
                                                                </Grid>
                                                            </Box>
                                                        )}
                                                        {isProfileEditing && videoAskResponses[0].response === null && (
                                                            <NodataCard onClick={() => setEditPreferredPractitioner(true)} />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {(isProfileEditing || videoAskResponses[0].response !== null) && (
                                                <Divider className={classes.sidebarDivider} />
                                            )}
                                            {/* End 1 */}
                                            <Grid container alignItems="center" spacing={gridSpacing}>
                                                <Grid item sx={{ width: '100%' }}>
                                                    <LeftRightAlignWrapper>
                                                        <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                            Skills
                                                        </Typography>
                                                        {isProfileEditing && (
                                                            <EditButtonProfile
                                                                buttonTapped={() => showEditPopup(ProfileEditableFields.SKILLS)}
                                                            />
                                                        )}
                                                    </LeftRightAlignWrapper>

                                                    <Box sx={{ paddingTop: 2 }}>
                                                        <Grid item sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                                            {profileData.specialist_skills.slice(0, displayCount).map((item, index) => (
                                                                <Typography
                                                                    key={index}
                                                                    variant="h1"
                                                                    sx={{
                                                                        display: 'inline-flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        fontSize: '12px',
                                                                        fontWeight: 'medium',
                                                                        background: '#F5F5F5',
                                                                        height: 'auto',
                                                                        borderRadius: '5px',
                                                                        padding: '10px 16px'
                                                                    }}
                                                                >
                                                                    {item.dv_skills.skill_title}
                                                                </Typography>
                                                            ))}
                                                        </Grid>
                                                    </Box>
                                                    {profileData.specialist_skills.length > initialDisplayCount && (
                                                        // If the count is greater than the display count
                                                        <Box sx={{ paddingTop: 3 }}>
                                                            {
                                                                <Button
                                                                    variant="outlined"
                                                                    sx={{ width: '100%' }}
                                                                    onClick={handleShowMoreSkills}
                                                                >
                                                                    {!showAllSkills
                                                                        ? 'View +' +
                                                                          `${profileData.specialist_skills.length - initialDisplayCount}` +
                                                                          ' More'
                                                                        : 'Show less'}
                                                                </Button>
                                                            }
                                                        </Box>
                                                    )}
                                                    {profileData.specialist_skills.length == 0 && !isProfileEditing && (
                                                        <Typography variant="caption" sx={{ fontSize: '14px' }}>
                                                            No skill added
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Divider className={classes.sidebarDivider} />
                                            {/* Types of Client (Video ask) */}
                                            {(isProfileEditing || videoAskResponses[1].response !== null) && (
                                                <Grid container alignItems="center" spacing={gridSpacing}>
                                                    <Grid item sx={{ width: '100%' }}>
                                                        <LeftRightAlignWrapper>
                                                            <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                                Types of Client
                                                            </Typography>

                                                            {isProfileEditing && videoAskResponses[1].response !== null && (
                                                                <DeleteButtonProfile actionConfirmed={() => deleteVideoAskContent(1)} />
                                                            )}
                                                        </LeftRightAlignWrapper>
                                                        {videoAskResponses[1].response !== null && (
                                                            <Box sx={{ paddingTop: 2, paddingBottom: 1 }}>
                                                                <Grid item sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                                                    {videoAskResponses[1].response.map((item, index) => {
                                                                        return (
                                                                            <Typography
                                                                                key={index}
                                                                                variant="h1"
                                                                                sx={{
                                                                                    display: 'inline-flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    fontSize: '12px',
                                                                                    fontWeight: 'medium',
                                                                                    background: '#F5F5F5',
                                                                                    height: 'auto',
                                                                                    borderRadius: '5px',
                                                                                    padding: '10px 16px'
                                                                                }}
                                                                            >
                                                                                {item}
                                                                            </Typography>
                                                                        );
                                                                    })}
                                                                </Grid>
                                                            </Box>
                                                        )}
                                                        {isProfileEditing && videoAskResponses[1].response === null && (
                                                            <NodataCard onClick={() => setEditTypesOfClients(true)} />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {(isProfileEditing || videoAskResponses[1].response !== null) && (
                                                <Divider className={classes.sidebarDivider} />
                                            )}
                                            {/* End 2 */}
                                            <Grid container alignItems="center" spacing={gridSpacing}>
                                                <Grid item sx={{ width: '100%' }}>
                                                    <LeftRightAlignWrapper>
                                                        <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                            Industry Experience
                                                        </Typography>
                                                        {isProfileEditing && (
                                                            <EditButtonProfile
                                                                buttonTapped={() =>
                                                                    showEditPopup(ProfileEditableFields.INDUSTRY_EXPERIENCE)
                                                                }
                                                            />
                                                        )}
                                                    </LeftRightAlignWrapper>

                                                    <Box sx={{ paddingTop: 2 }}>
                                                        <Grid item sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                                            {profileData?.industry_experience?.map((item, index) => (
                                                                <Typography
                                                                    key={index}
                                                                    variant="h1"
                                                                    sx={{
                                                                        display: 'inline-flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        fontSize: '12px',
                                                                        fontWeight: 'medium',
                                                                        background: '#F5F5F5',
                                                                        height: 'auto',
                                                                        borderRadius: '5px',
                                                                        padding: '10px 16px'
                                                                    }}
                                                                >
                                                                    {/* {console.log(item.dv_industry_experiences.industry_experience,"_______item______")} */}
                                                                    {/* {item?.dv_industry_experiences && item?.dv_industry_experiences } */}
                                                                    {item?.dv_industry_experiences?.industry_experience}
                                                                </Typography>
                                                            ))}
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </MainCard>
                                    </Box>
                                    <MainCard>
                                        <Grid container alignItems="center" spacing={gridSpacing}>
                                            <Grid item>
                                                <div className={classes.sideAvatarIcon1}>
                                                    <BusinessCenterIcon />
                                                </div>
                                            </Grid>

                                            <Grid item xs zeroMinWidth>
                                                <LeftRightAlignWrapper>
                                                    <div>
                                                        <Typography variant="subtitle2">Experience Level</Typography>
                                                        <Typography
                                                            color="#4D4D4D"
                                                            component="div"
                                                            sx={{ mb: '5px', fontSize: '14px', fontWeight: 'medium' }}
                                                        >
                                                            {experienceLevel !== '' ? experienceLevel : '--'}
                                                        </Typography>
                                                    </div>
                                                    {isProfileEditing && (
                                                        <EditButtonProfile
                                                            buttonTapped={() => showEditPopup(ProfileEditableFields.EXPERIENCE_LEVEL)}
                                                        />
                                                    )}
                                                </LeftRightAlignWrapper>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.sidebarDivider} />
                                        <Grid container alignItems="center" spacing={gridSpacing}>
                                            <Grid item>
                                                <div className={classes.sideAvatarIcon2}>
                                                    <PersonIcon />
                                                </div>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <Typography variant="subtitle2">Profile Type</Typography>
                                                {profileData.category ? (
                                                    <Typography
                                                        color="#4D4D4D"
                                                        component="div"
                                                        sx={{ mb: '5px', fontSize: '14px', fontWeight: 'medium' }}
                                                    >
                                                        {profileData?.category?.charAt(0).toUpperCase() +
                                                            profileData?.category?.substring(1)}
                                                    </Typography>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </MainCard>
                                </Grid>
                                <Grid item xs={12}>
                                    <MainCard>
                                        <Grid container alignItems="center" spacing={gridSpacing}>
                                            <Grid item>
                                                <div className={classes.sideAvatarIcon3}>
                                                    <CallIcon />
                                                </div>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <Typography variant="subtitle2">Phone</Typography>
                                                <Typography
                                                    color="#4D4D4D"
                                                    component="div"
                                                    sx={{ mb: '5px', fontSize: '14px', fontWeight: 'medium' }}
                                                >
                                                    {` ${profileData.phone_code ?? ''} ${profileData.phone_number ?? '--'}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.sidebarDivider} />
                                        <Grid container alignItems="center" spacing={gridSpacing}>
                                            <Grid item>
                                                <div className={classes.sideAvatarIcon4}>
                                                    <MailIcon />
                                                </div>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <Typography variant="subtitle2">Mail</Typography>
                                                <Typography
                                                    color="#4D4D4D"
                                                    component="div"
                                                    sx={{ mb: '5px', fontSize: '14px', fontWeight: 'medium' }}
                                                >
                                                    {profileData.email}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.sidebarDivider} />
                                        <Grid container alignItems="center" spacing={gridSpacing}>
                                            <Grid item>
                                                <div className={classes.sideAvatarIcon5}>
                                                    <RoomIcon />
                                                </div>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <Typography variant="subtitle2">Location</Typography>
                                                <Typography
                                                    color="#4D4D4D"
                                                    component="div"
                                                    sx={{ mb: '5px', fontSize: '14px', fontWeight: 'medium' }}
                                                >
                                                    {`${profileData.suburb ? profileData.suburb + ', ' : ''}${
                                                        profileData.state ? profileData.state + ', ' : ''
                                                    }${profileData.country ?? '--'}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.sidebarDivider} />
                                        <Grid container alignItems="center" spacing={gridSpacing}>
                                            <Grid item>
                                                <div className={classes.sideAvatarIcon6}>
                                                    <DescriptionSharpIcon />
                                                </div>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <Typography variant="subtitle2">Billing Details</Typography>
                                                <Typography
                                                    color="#4D4D4D"
                                                    component="div"
                                                    sx={{ mb: '5px', fontSize: '14px', fontWeight: 'medium' }}
                                                >
                                                    {`${
                                                        profileData.billing_account != null
                                                            ? profileData.billing_account?.company_name
                                                            : '--'
                                                    }`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </MainCard>
                                    <Box sx={{ marginTop: 3 }}>
                                        <MainCard>
                                            <Grid container alignItems="center" spacing={gridSpacing}>
                                                <Grid item sx={{ width: '100%' }}>
                                                    <LeftRightAlignWrapper>
                                                        <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                            Language(s)
                                                        </Typography>
                                                        {isProfileEditing && (
                                                            <EditButtonProfile
                                                                buttonTapped={() => showEditPopup(ProfileEditableFields.LANGUAGES)}
                                                            />
                                                        )}
                                                    </LeftRightAlignWrapper>
                                                    <Box sx={{ paddingTop: 2 }}>
                                                        <Grid item sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                                            {profileData.specialist_languages.map((item, index) => (
                                                                <Typography
                                                                    key={index}
                                                                    variant="h1"
                                                                    sx={{
                                                                        display: 'inline-flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        fontSize: '12px',
                                                                        fontWeight: 'medium',
                                                                        background: '#F5F5F5',
                                                                        height: 'auto',
                                                                        borderRadius: '5px',
                                                                        padding: '10px 16px'
                                                                    }}
                                                                >
                                                                    {item.dv_language.language_title}
                                                                </Typography>
                                                            ))}
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </MainCard>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} id="basic-info">
                                    <MainCard>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography sx={{ color: '#0094FF', fontWeight: 500, fontSize: '16px' }}>
                                                    PERSONAL DETAILS
                                                </Typography>
                                            </Grid>
                                            {/* Start (Video Ask) */}
                                            {(isProfileEditing || videoAskResponses[2].response !== null) && (
                                                <Grid item xs={12}>
                                                    <LeftRightAlignWrapper>
                                                        <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                            About me
                                                        </Typography>

                                                        {isProfileEditing && videoAskResponses[2].response !== null && (
                                                            <DeleteButtonProfile actionConfirmed={() => deleteVideoAskContent(2)} />
                                                        )}
                                                    </LeftRightAlignWrapper>
                                                    {isProfileEditing && videoAskResponses[2].response === null && (
                                                        <NodataCard onClick={() => addVideoAskContent(2)} />
                                                    )}
                                                    {videoAskResponses[2].type === 'video' && videoAskResponses[2].response !== null && (
                                                        <VideoCard
                                                            thumbnail={videoAskResponses[2].response?.thumbnail}
                                                            videoURL={videoAskResponses[2].response?.videoURL}
                                                        />
                                                    )}
                                                    {videoAskResponses[2].type === 'audio' && (
                                                        <AudioCard audioURL={videoAskResponses[2].response?.audioURL} />
                                                    )}
                                                    {videoAskResponses[2].type === 'text' && (
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: '14px',
                                                                wordWrap: 'break-word'
                                                            }}
                                                        >
                                                            {videoAskResponses[2].response}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            )}
                                            {/* End (Video Ask) */}
                                            <Grid item xs={12}>
                                                <Box>
                                                    <LeftRightAlignWrapper>
                                                        <Typography
                                                            variant="h4"
                                                            sx={{ fontSize: '20px', fontWeight: 'medium', marginBottom: 2 }}
                                                        >
                                                            More about me
                                                        </Typography>
                                                        {isProfileEditing && (
                                                            <EditButtonProfile
                                                                buttonTapped={() => showEditPopup(ProfileEditableFields.ABOUT_ME)}
                                                            />
                                                        )}
                                                    </LeftRightAlignWrapper>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: '14px',
                                                            wordWrap: 'break-word'
                                                            // maxHeight: { xs: 100, sm: 'none' },
                                                            // overflow: { xs: 'auto', sm: 'none' }
                                                        }}
                                                    >
                                                        {profileData.professional_experience_info}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        {/* {(isProfileEditing || videoAskResponses[2].response !== null) && ( */}
                                        <Divider className={classes.sidebarDivider} />
                                        {/* )} */}
                                        {/* Start (Video Ask) */}
                                        {(isProfileEditing || videoAskResponses[3].response !== null) && (
                                            <Grid container spacing={2}>
                                                {(isProfileEditing || videoAskResponses[3].response !== null) && (
                                                    <Grid item xs={12}>
                                                        <LeftRightAlignWrapper>
                                                            <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                                Work Showcase
                                                            </Typography>
                                                            {isProfileEditing && videoAskResponses[3].response !== null && (
                                                                <DeleteButtonProfile actionConfirmed={() => deleteVideoAskContent(3)} />
                                                            )}
                                                        </LeftRightAlignWrapper>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12}>
                                                    {isProfileEditing && videoAskResponses[3].response === null && (
                                                        <NodataCard onClick={() => addVideoAskContent(3)} />
                                                    )}
                                                    {videoAskResponses[3].type === 'video' && videoAskResponses[3].response !== null && (
                                                        <VideoCard
                                                            thumbnail={videoAskResponses[3].response?.thumbnail}
                                                            videoURL={videoAskResponses[3].response?.videoURL}
                                                        />
                                                    )}
                                                    {videoAskResponses[3].type === 'audio' && videoAskResponses[3].response !== null && (
                                                        <AudioCard audioURL={videoAskResponses[3].response?.audioURL} />
                                                    )}
                                                    {videoAskResponses[3].type === 'text' && videoAskResponses[3].response !== null && (
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: '14px',
                                                                wordWrap: 'break-word'
                                                            }}
                                                        >
                                                            {videoAskResponses[3].response}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )}
                                        {(isProfileEditing || videoAskResponses[3].response !== null) && (
                                            <Divider className={classes.sidebarDivider} />
                                        )}
                                        {/* End (Video Ask) */}
                                        {/* Start (Video Ask) */}
                                        {(isProfileEditing || videoAskResponses[4].response !== null) && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <LeftRightAlignWrapper>
                                                        <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                            How People Describe Me
                                                        </Typography>
                                                        {isProfileEditing && videoAskResponses[4].response !== null && (
                                                            <DeleteButtonProfile actionConfirmed={() => deleteVideoAskContent(4)} />
                                                        )}
                                                    </LeftRightAlignWrapper>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {isProfileEditing && videoAskResponses[4].response === null && (
                                                        <NodataCard onClick={() => addVideoAskContent(4)} />
                                                    )}
                                                    {videoAskResponses[4].type === 'video' && videoAskResponses[4].response !== null && (
                                                        <VideoCard
                                                            thumbnail={videoAskResponses[4].response?.thumbnail}
                                                            videoURL={videoAskResponses[4].response?.videoURL}
                                                        />
                                                    )}
                                                    {videoAskResponses[4].type === 'audio' && videoAskResponses[4].response !== null && (
                                                        <AudioCard audioURL={videoAskResponses[4].response?.audioURL} />
                                                    )}
                                                    {videoAskResponses[4].type === 'text' && videoAskResponses[4].response !== null && (
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: '14px',
                                                                wordWrap: 'break-word'
                                                            }}
                                                        >
                                                            {videoAskResponses[4].response}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )}
                                        {(isProfileEditing || videoAskResponses[4].response !== null) && (
                                            <Divider className={classes.sidebarDivider} />
                                        )}
                                        {/* End (Video Ask) */}
                                        {/* Start (Video Ask) */}
                                        {(isProfileEditing || videoAskResponses[5].response !== null) && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <LeftRightAlignWrapper>
                                                        <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                            Work Style
                                                        </Typography>
                                                        {isProfileEditing && videoAskResponses[5].response !== null && (
                                                            <DeleteButtonProfile actionConfirmed={() => deleteVideoAskContent(5)} />
                                                        )}
                                                    </LeftRightAlignWrapper>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {/* <AudioCard /> */}
                                                    {isProfileEditing && videoAskResponses[5].response === null && (
                                                        <NodataCard onClick={() => addVideoAskContent(5)} />
                                                    )}
                                                    {videoAskResponses[5].type === 'video' && videoAskResponses[5].response !== null && (
                                                        <VideoCard
                                                            thumbnail={videoAskResponses[5].response?.thumbnail}
                                                            videoURL={videoAskResponses[5].response?.videoURL}
                                                        />
                                                    )}
                                                    {videoAskResponses[5].type === 'audio' && videoAskResponses[5].response !== null && (
                                                        <AudioCard audioURL={videoAskResponses[5].response?.audioURL} />
                                                    )}
                                                    {videoAskResponses[5].type === 'text' && videoAskResponses[5].response !== null && (
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: '14px',
                                                                wordWrap: 'break-word'
                                                            }}
                                                        >
                                                            {videoAskResponses[5].response}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )}
                                        {/* End (Video Ask) */}
                                    </MainCard>
                                </Grid>
                                {/* Start (Video Ask) */}
                                {(isProfileEditing || videoAskResponses[6].response !== null) && (
                                    <Grid item xs={12} id="skills">
                                        <MainCard>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{ fontSize: '16px', fontWeight: 'medium', color: '#0094FF' }}
                                                    >
                                                        SKILLS
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LeftRightAlignWrapper>
                                                        <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                            Tools & Technologies
                                                        </Typography>
                                                        {isProfileEditing && videoAskResponses[6].response !== null && (
                                                            <DeleteButtonProfile actionConfirmed={() => deleteVideoAskContent(6)} />
                                                        )}
                                                    </LeftRightAlignWrapper>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid>
                                                        {/* <VideoCard /> */}
                                                        {isProfileEditing && videoAskResponses[6].response === null && (
                                                            <NodataCard onClick={() => addVideoAskContent(6)} />
                                                        )}
                                                        {videoAskResponses[6].type === 'video' &&
                                                            videoAskResponses[6].response !== null && (
                                                                <VideoCard
                                                                    thumbnail={videoAskResponses[6].response?.thumbnail}
                                                                    videoURL={videoAskResponses[6].response?.videoURL}
                                                                />
                                                            )}
                                                        {videoAskResponses[6].type === 'audio' &&
                                                            videoAskResponses[6].response !== null && (
                                                                <AudioCard audioURL={videoAskResponses[6].response?.audioURL} />
                                                            )}
                                                        {videoAskResponses[6].type === 'text' && videoAskResponses[6].response !== null && (
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    wordWrap: 'break-word'
                                                                }}
                                                            >
                                                                {videoAskResponses[6].response}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </MainCard>
                                    </Grid>
                                )}
                                {/* End (Video Ask) */}
                                <Grid item xs={12} id="certificate">
                                    <MainCard>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h4" sx={{ fontSize: '16px', fontWeight: 'medium', color: '#0094FF' }}>
                                                    CERTIFICATIONS
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {/* <Stack sx={{ mt: 1 }} spacing={3}> */}
                                                <Timeline sx={{ mt: -1 }}>
                                                    {profileData.educations && profileData.educations.length ? (
                                                        profileData.educations.map((education, index) => (
                                                            <TimelineItem sx={{ m: 0 }} key={index}>
                                                                <TimelineSeparator>
                                                                    <TimelineDot sx={{ background: '#E3F2FD' }} />
                                                                    <TimelineConnector sx={{ background: '#E3F2FD' }} />
                                                                </TimelineSeparator>
                                                                <TimelineOppositeContent sx={{ maxWidth: '1px', px: '0px' }} />
                                                                <TimelineContent>
                                                                    <Stack direction="column" spacing={0.25}>
                                                                        <LeftRightAlignWrapper>
                                                                            <Typography
                                                                                variant="h1"
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    fontWeight: 'medium',
                                                                                    color: '#111111',
                                                                                    textTransform: 'capitalize'
                                                                                }}
                                                                            >
                                                                                {education.degree}
                                                                            </Typography>
                                                                            {isProfileEditing && (
                                                                                <div>
                                                                                    <DeleteButtonProfile
                                                                                        sx={{ mr: 1 }}
                                                                                        actionConfirmed={() =>
                                                                                            deleteCertificateAction(education)
                                                                                        }
                                                                                        message={
                                                                                            'Are you sure, you want to delete this certificate?'
                                                                                        }
                                                                                    />
                                                                                    <EditButtonProfile
                                                                                        buttonTapped={() => {
                                                                                            setSelectedCertificate(education);
                                                                                            showEditPopup(
                                                                                                ProfileEditableFields.CERTIFICATION
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </LeftRightAlignWrapper>
                                                                        <Typography
                                                                            variant="body"
                                                                            sx={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 'medium',
                                                                                color: '#4D4D4D',
                                                                                textTransform: 'capitalize'
                                                                            }}
                                                                        >
                                                                            {education.specialisation}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body"
                                                                            sx={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 'medium',
                                                                                color: '#4D4D4D',
                                                                                textTransform: 'capitalize'
                                                                            }}
                                                                        >
                                                                            {education.name}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            sx={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 'medium',
                                                                                color: '#4D4D4D'
                                                                            }}
                                                                        >
                                                                            {dateRangeString(education.started_at, education.finished_at)}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body"
                                                                            sx={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 'regular',
                                                                                color: '#909090',
                                                                                maxHeight: { xs: 200, md: 'none' },
                                                                                overflow: { xs: 'auto', md: 'none' }
                                                                            }}
                                                                        >
                                                                            {education.description}
                                                                        </Typography>
                                                                        {/* <Divider sx={{ pt: 2 }} /> */}
                                                                    </Stack>
                                                                </TimelineContent>
                                                            </TimelineItem>
                                                        ))
                                                    ) : (
                                                        <Grid item>
                                                            <Typography variant="caption" sx={{ fontSize: '14px', ml: -2 }}>
                                                                No certifications added
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Timeline>
                                                {/* </Stack> */}
                                                {isProfileEditing && (
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => showEditPopup(ProfileEditableFields.ADD_CERTIFICATION)}
                                                    >
                                                        + Add More
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </MainCard>
                                </Grid>
                                <Grid item xs={12} id="projects">
                                    <MainCard>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h4" sx={{ fontSize: '16px', fontWeight: 'medium', color: '#0094FF' }}>
                                                    EXPERIENCE
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                    Projects
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack sx={{ mt: 1 }} direction="column" spacing={3}>
                                                    {profileData.work_experiences && profileData.work_experiences.length ? (
                                                        profileData.work_experiences.map((experience, index) => (
                                                            <Box
                                                                sx={{ background: '#FAFAFA', padding: 3, borderRadius: 3, width: '100%' }}
                                                                key={index}
                                                            >
                                                                <Stack direction="row" sx={{ width: '100%' }} spacing={2}>
                                                                    <Avatar
                                                                        sx={{
                                                                            color: 'white',
                                                                            bgcolor: '#0094FF',
                                                                            fontSize: '16px',
                                                                            fontWeight: 'bold',
                                                                            pt: 0.25,
                                                                            background: `${stringToColor(experience.name)}`,
                                                                            textTransform: 'capitalize'
                                                                        }}
                                                                    >
                                                                        {getInitials(experience.name)}
                                                                    </Avatar>
                                                                    <Stack direction="column" sx={{ width: '100%' }} spacing={0.5}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={12}>
                                                                                <LeftRightAlignWrapper>
                                                                                    <Typography
                                                                                        variant="h1"
                                                                                        sx={{
                                                                                            fontSize: '16px',
                                                                                            fontWeight: 'medium',
                                                                                            color: '#111111',
                                                                                            textTransform: 'capitalize'
                                                                                        }}
                                                                                    >
                                                                                        {experience.name}
                                                                                    </Typography>
                                                                                    {isProfileEditing && (
                                                                                        <div>
                                                                                            <DeleteButtonProfile
                                                                                                sx={{ mr: 1 }}
                                                                                                actionConfirmed={() =>
                                                                                                    deleteProjectAction(experience)
                                                                                                }
                                                                                                message={
                                                                                                    'Are you sure, you want to delete this project?'
                                                                                                }
                                                                                            />
                                                                                            <EditButtonProfile
                                                                                                buttonTapped={() => {
                                                                                                    showEditPopup(
                                                                                                        ProfileEditableFields.PROJECTS
                                                                                                    );
                                                                                                    setSelectedProject(experience);
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </LeftRightAlignWrapper>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Typography
                                                                            variant="body"
                                                                            sx={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 'medium',
                                                                                color: '#4D4D4D',
                                                                                textTransform: 'capitalize'
                                                                            }}
                                                                        >
                                                                            {experience.position}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body"
                                                                            sx={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 'medium',
                                                                                color: '#4D4D4D'
                                                                            }}
                                                                        >
                                                                            {dateRangeString(experience.started_at, experience.finished_at)}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="caption"
                                                                            sx={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 'medium',
                                                                                color: '#99ABB9',
                                                                                textTransform: 'capitalize'
                                                                            }}
                                                                        >
                                                                            {`${experience.city}, ${experience.country}`}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body"
                                                                            sx={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 'regular',
                                                                                color: '#909090',
                                                                                pt: 1,
                                                                                maxHeight: { xs: 200, md: 'none' },
                                                                                overflow: { xs: 'auto', md: 'none' }
                                                                            }}
                                                                        >
                                                                            {experience.description}
                                                                        </Typography>
                                                                    </Stack>
                                                                </Stack>
                                                            </Box>
                                                        ))
                                                    ) : (
                                                        <Grid item>
                                                            <Typography variant="caption" sx={{ fontSize: '14px' }}>
                                                                No projects added
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Stack>
                                                {isProfileEditing && (
                                                    <Button
                                                        variant="outlined"
                                                        sx={{ mt: 3 }}
                                                        onClick={() => showEditPopup(ProfileEditableFields.ADD_PROJECT)}
                                                    >
                                                        + Add More
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </MainCard>
                                </Grid>
                                <Grid item xs={12} id="projectinterest">
                                    <MainCard>
                                        <Grid item xs={12}>
                                            <Typography variant="h4" sx={{ fontSize: '16px', fontWeight: 'medium', color: '#0094FF' }}>
                                                PROJECT INTERESTS
                                            </Typography>
                                        </Grid>
                                        {/* Start (Video Ask) */}
                                        {(isProfileEditing || videoAskResponses[7].response !== null) && (
                                            <Grid sx={{ paddingBottom: 2 }}>
                                                <Grid xs={12} sx={{ marginTop: 2 }}>
                                                    <LeftRightAlignWrapper>
                                                        <Typography variant="h4" sx={{ fontSize: '18px', fontWeight: 'medium' }}>
                                                            Ideal Project / Client Profile
                                                        </Typography>
                                                        {isProfileEditing && videoAskResponses[7].response !== null && (
                                                            <DeleteButtonProfile actionConfirmed={() => deleteVideoAskContent(7)} />
                                                        )}
                                                    </LeftRightAlignWrapper>
                                                    <Grid>
                                                        {/* <AudioCard /> */}
                                                        {isProfileEditing && videoAskResponses[7].response === null && (
                                                            <NodataCard onClick={() => addVideoAskContent(7)} />
                                                        )}
                                                        {videoAskResponses[7].type === 'video' &&
                                                            videoAskResponses[7].response !== null && (
                                                                <VideoCard
                                                                    thumbnail={videoAskResponses[7].response?.thumbnail}
                                                                    videoURL={videoAskResponses[7].response?.videoURL}
                                                                />
                                                            )}
                                                        {videoAskResponses[7].type === 'audio' &&
                                                            videoAskResponses[7].response !== null && (
                                                                <AudioCard audioURL={videoAskResponses[7].response?.audioURL} />
                                                            )}
                                                        {videoAskResponses[7].type === 'text' && videoAskResponses[7].response !== null && (
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    wordWrap: 'break-word'
                                                                }}
                                                            >
                                                                {videoAskResponses[7].response}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {(isProfileEditing || videoAskResponses[7].response !== null) && (
                                            <Divider className={classes.sidebarDivider} />
                                        )}
                                        {/* End (Video Ask) */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <LeftRightAlignWrapper>
                                                    <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                        Project Interests
                                                    </Typography>
                                                    {isProfileEditing && (
                                                        <EditButtonProfile
                                                            buttonTapped={() => showEditPopup(ProfileEditableFields.PROJECT_INTEREST)}
                                                        />
                                                    )}
                                                </LeftRightAlignWrapper>
                                            </Grid>
                                            {profileData.project_types && profileData.project_types.length ? (
                                                profileData.project_types.map((projectType, index) => (
                                                    <Grid item xs={12} mt={0.5} key={index}>
                                                        <Stack direction="row" alignItems="center" spacing={1.5}>
                                                            <CheckCircleIcon sx={{ color: '#59E239', height: '18px', width: '18px' }} />
                                                            <Stack direction="column">
                                                                <Typography
                                                                    variant="body"
                                                                    sx={{ fontSize: '14px', fontWeight: 'medium' }}
                                                                    key={index}
                                                                >
                                                                    {projectType.dv_project_types.project_type_title}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                ))
                                            ) : (
                                                <Grid item>
                                                    <Typography variant="caption" sx={{ fontSize: '14px' }}>
                                                        No interests added
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Divider className={classes.sidebarDivider} />
                                        {/* Start (Video Ask) */}
                                        {(isProfileEditing || videoAskResponses[8].response !== null) && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <LeftRightAlignWrapper>
                                                        <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                            Understanding of Meaningful Work
                                                        </Typography>
                                                        {isProfileEditing && videoAskResponses[8].response !== null && (
                                                            <DeleteButtonProfile actionConfirmed={() => deleteVideoAskContent(8)} />
                                                        )}
                                                    </LeftRightAlignWrapper>
                                                    <Grid sx={{ width: '100%', paddingTop: 2 }}>
                                                        {/* <VideoCard /> */}
                                                        {isProfileEditing && videoAskResponses[8].response === null && (
                                                            <NodataCard onClick={() => addVideoAskContent(8)} />
                                                        )}
                                                        {videoAskResponses[8].type === 'video' &&
                                                            videoAskResponses[8].response !== null && (
                                                                <VideoCard
                                                                    thumbnail={videoAskResponses[8].response?.thumbnail}
                                                                    videoURL={videoAskResponses[8].response?.videoURL}
                                                                />
                                                            )}
                                                        {videoAskResponses[8].type === 'audio' &&
                                                            videoAskResponses[8].response !== null && (
                                                                <AudioCard audioURL={videoAskResponses[8].response?.audioURL} />
                                                            )}
                                                        {videoAskResponses[8].type === 'text' && videoAskResponses[8].response !== null && (
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    wordWrap: 'break-word'
                                                                }}
                                                            >
                                                                {videoAskResponses[8].response}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {(isProfileEditing || videoAskResponses[8].response !== null) && (
                                            <Divider className={classes.sidebarDivider} />
                                        )}
                                        {/* End (Video Ask) */}
                                        {/* Start (Video Ask) */}
                                        {(isProfileEditing || videoAskResponses[9].response !== null) && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <LeftRightAlignWrapper>
                                                        <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: 'medium' }}>
                                                            Admired Brands or Companies
                                                        </Typography>
                                                        {isProfileEditing && videoAskResponses[9].response !== null && (
                                                            <DeleteButtonProfile actionConfirmed={() => deleteVideoAskContent(9)} />
                                                        )}
                                                    </LeftRightAlignWrapper>
                                                    <Grid sx={{ width: '100%', paddingTop: 2 }}>
                                                        {/* <AudioCard /> */}
                                                        {isProfileEditing && videoAskResponses[9].response === null && (
                                                            <NodataCard onClick={() => setEditAdmiredBrands(true)} />
                                                        )}
                                                        {videoAskResponses[9].type === 'video' &&
                                                            videoAskResponses[9].response !== null && (
                                                                <VideoCard
                                                                    thumbnail={videoAskResponses[9].response?.thumbnail}
                                                                    videoURL={videoAskResponses[9].response?.videoURL}
                                                                />
                                                            )}
                                                        {videoAskResponses[9].type === 'audio' &&
                                                            videoAskResponses[9].response !== null && (
                                                                <AudioCard audioURL={videoAskResponses[9].response?.audioURL} />
                                                            )}
                                                        {videoAskResponses[9].type === 'text' && videoAskResponses[9].response !== null && (
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    wordWrap: 'break-word'
                                                                }}
                                                            >
                                                                {videoAskResponses[9].response}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {/* End (Video Ask) */}
                                    </MainCard>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal
                    open={openBillingPopup}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {/* Edit billing - STARTS */}
                    <MainCard
                        style={modalStyle}
                        className={classes.paper}
                        content={false}
                        secondary={
                            <IconButton onClick={handleClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                    >
                        <CardContent>
                            <div item xs={12}>
                                <h2 fullWidth style={{ textAlign: 'center', color: 'black' }}>
                                    Edit Billing Details
                                </h2>
                            </div>
                            <Formik
                                initialValues={{
                                    id: profileData.billing_account?.id ?? 0,
                                    company_name: profileData.billing_account?.company_name ?? '',
                                    company_address: profileData.billing_account?.company_address ?? '',
                                    owner_name: profileData.billing_account?.owner_name ?? '',
                                    owner_email: profileData.billing_account?.owner_email ?? '',
                                    owner_phone: profileData.billing_account?.owner_phone ?? '',
                                    company_abn: profileData.billing_account?.company_abn ?? '',
                                    bank_name: profileData.billing_account?.bank_name ?? '',
                                    account_name: profileData.billing_account?.account_name ?? '',
                                    account_number: profileData.billing_account?.account_number ?? '',
                                    bsb_number: profileData.billing_account?.bsb_number ?? '',
                                    swift_code: profileData.billing_account?.swift_code ?? '',
                                    gst_vat: profileData.billing_account?.gst_vat ?? '',
                                    submit: null
                                }}
                                validationSchema={Yup.object().shape({
                                    company_name: Yup.string().required('Company Name is required'),
                                    company_address: Yup.string().required('Company Address is required'),
                                    owner_name: Yup.string(),
                                    owner_email: Yup.string().email('Must be a valid email'),
                                    owner_phone: Yup.string().max(15),
                                    company_abn: Yup.string(),
                                    bank_name: Yup.string().required('Bank Name is required'),
                                    account_name: Yup.string().required('Account Name is required'),
                                    account_number: Yup.string().required('Account Number is required'),
                                    bsb_number: Yup.string().required('BSB Number is required'),
                                    swift_code: Yup.string(),
                                    gst_vat: Yup.string()
                                })}
                                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                    let data = {
                                        id: values.id,
                                        company_name: values.company_name,
                                        company_address: values.company_address,
                                        owner_name: values.owner_name,
                                        owner_email: values.owner_email,
                                        owner_phone: values.owner_phone,
                                        company_abn: values.company_abn,
                                        bank_name: values.bank_name,
                                        account_name: values.account_name,
                                        account_number: values.account_number,
                                        bsb_number: values.bsb_number,
                                        swift_code: values.swift_code,
                                        gst_vat: values.gst_vat
                                    };
                                    //save billing Details
                                    try {
                                        // console.log(values);
                                        await axios.put(`${config.restAPI.baseURL}/user/billing-account/update`, data, {
                                            headers: { Accept: 'application/json', Authorization: `Bearer ${accessToken}` }
                                        });
                                        showSnackbar('Billing Details Updated.', 'success');
                                        handleClose();
                                        //reload user details
                                        getUserProfile();
                                    } catch (err) {
                                        console.log(err);
                                    }
                                }}
                            >
                                {({ errors, handleChange, handleSubmit, isSubmitting, values }) => (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <Grid container spacing={gridSpacing}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="company_name"
                                                    name="company_name"
                                                    label="Company Name"
                                                    size="small"
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    value={values.company_name}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error id="company_name">
                                                    {' '}
                                                    {errors.company_name}{' '}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="company_address"
                                                    name="company_address"
                                                    label="Company Address"
                                                    size="small"
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    value={values.company_address}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error id="company_address">
                                                    {' '}
                                                    {errors.company_address}{' '}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="owner_name"
                                                    name="owner_name"
                                                    label="Owner Name"
                                                    size="small"
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    value={values.owner_name}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error id="owner_name">
                                                    {' '}
                                                    {errors.owner_name}{' '}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="owner_email"
                                                    name="owner_email"
                                                    label="Owner Email"
                                                    size="small"
                                                    inputProps={{ style: { textTransform: 'lowercase' } }}
                                                    value={values.owner_email}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error id="owner_email">
                                                    {' '}
                                                    {errors.owner_email}{' '}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="owner_phone"
                                                    name="owner_phone"
                                                    label="Owner Phone"
                                                    size="small"
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    value={values.owner_phone}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error id="owner_phone">
                                                    {' '}
                                                    {errors.owner_phone}{' '}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="company_abn"
                                                    name="company_abn"
                                                    label="Company ABN"
                                                    size="small"
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    value={values.company_abn}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error id="company_abn">
                                                    {' '}
                                                    {errors.company_abn}{' '}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="bank_name"
                                                    name="bank_name"
                                                    label="Bank Name"
                                                    size="small"
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    value={values.bank_name}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error id="bank_name">
                                                    {' '}
                                                    {errors.bank_name}{' '}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="account_name"
                                                    name="account_name"
                                                    label="Account Name"
                                                    size="small"
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    value={values.account_name}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error id="account_name">
                                                    {' '}
                                                    {errors.account_name}{' '}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="account_number"
                                                    name="account_number"
                                                    label="Account Number"
                                                    size="small"
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    value={values.account_number}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error id="account_number">
                                                    {' '}
                                                    {errors.account_number}{' '}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="bsb_number"
                                                    name="bsb_number"
                                                    label="BSB Number"
                                                    size="small"
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    value={values.bsb_number}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error id="bsb_number">
                                                    {' '}
                                                    {errors.bsb_number}{' '}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="swift_code"
                                                    name="swift_code"
                                                    label="SWIFT Code"
                                                    size="small"
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    value={values.swift_code}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error id="swift_code">
                                                    {' '}
                                                    {errors.swift_code}{' '}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="gst_vat"
                                                    name="gst_vat"
                                                    label="GST/VAT Y/N"
                                                    size="small"
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    value={values.gst_vat}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error id="gst_vat">
                                                    {' '}
                                                    {errors.gst_vat}{' '}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={6}></Grid>
                                                <Grid item xs={12} md={6} sx={{ padding: '20px 0px 20px 20px' }}>
                                                    <Grid container>
                                                        <Grid item xs={6} style={{ padding: '5px' }}>
                                                            <Button
                                                                fullWidth
                                                                size="large"
                                                                variant="text"
                                                                type="button"
                                                                onClick={handleClose}
                                                                style={{ backgroundColor: '#dbedfd' }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6} style={{ padding: '5px' }}>
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                type="submit"
                                                                size="large"
                                                                disabled={isSubmitting}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </CardContent>
                    </MainCard>
                    {/* Edit billing - ENDS */}
                </Modal>
            </Grid>
            {showEditProfilePopup && profileEditableField !== null && (
                <EditProfilePopups
                    showPopUp={showEditProfilePopup}
                    dismissPopUp={dismissEditProfilePopup}
                    editableField={profileEditableField}
                    profileData={profileData}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    setProfileData={setProfileData}
                    setUpdated={setUpdated}
                    selectedCertificate={selectedCertificate}
                    setSelectedCertificate={setSelectedCertificate}
                    // about={about}
                    // setAbout={setAbout}
                />
            )}
            {/* Video ask popup */}
            {selectedVideoAskIndex != null && (
                <VideoAskPopup
                    title={videoAskResponses[selectedVideoAskIndex].questionTitle}
                    videoToken={videoAskResponses[selectedVideoAskIndex].token}
                    closePopup={closeVideoAskPopUp}
                />
            )}
            {/* Preferred practitoner popup */}
            {editPrefferedPractitioner && (
                <EditPreferredPractitioner
                    open={editPrefferedPractitioner}
                    profileData={profileData}
                    questionData={videoAskResponses[0]}
                    closePopup={closeVideoAskPopUp}
                />
            )}
            {/* Types of clients practitoner popup */}
            {editTypesOfClients && (
                <EditTypesOfClients
                    open={editTypesOfClients}
                    profileData={profileData}
                    questionData={videoAskResponses[1]}
                    closePopup={closeVideoAskPopUp}
                />
            )}
            {/* Admired brands edit popup */}
            {editAdmiredBrands && (
                <EditAdmiredBrands
                    open={editAdmiredBrands}
                    profileData={profileData}
                    questionData={videoAskResponses[9]}
                    closePopup={closeVideoAskPopUp}
                />
            )}
        </Fragment>
    );
};

export default NewProfile;
